import React, { useEffect } from 'react';
import { smartFarm } from '../../data';
import './SmartFarming.css';

const SmartFarming: React.FC = () => {
  const [hoveredCard, setHoveredCard] = React.useState<number | null>(null);

  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  }, []);
  
  const handleMouseEnter = (index: number) => {
    setHoveredCard(index);
  };

  const handleMouseLeave = () => {
    setHoveredCard(null);
  };

  return (
    <div className="container-fluid p-0 overflow-hidden">
      <div className='  '>
        <video className='w-100 h-auto' muted loop autoPlay id="myVideo">
        <source src="/videos/Hydroponics.mp4" type="video/mp4"></source>
        </video>
    </div>

      {/* New row for the header and subheading */}
      <div className="row justify-content-center">
        <div className="col-md-12 d-flex justify-content-center">
          <div className="header-container">
            <h1 className="header-title">Smart Farming Solutions</h1>
            <p style={{ textAlign: 'center' }}>Discover Our Latest Innovations</p>
          </div>
        </div>
      </div>

      {/* Carousel */}
      <div className="row">
        <div className="col-md-8 mx-auto" style={{ border: '1px solid black' }}>
          <div id="myCarousel" className="carousel slide" data-ride="carousel">
            <div className="carousel-inner">
              <div className="carousel-item active">
                <img src="/images/spinach_hydro.png" alt="First Image" className="d-block w-100" />
              </div>
              <div className="carousel-item">
                <img src="/images/Hydroponics Farm.jpeg" alt="Second Image" className="d-block w-100" />
              </div>
              <div className="carousel-item">
                <img src="/images/greeny.png" alt="Third Image" className="d-block w-100" />
              </div>
            </div>
            <a className="carousel-control-prev" href="#myCarousel" role="button" data-slide="prev">
              <span className="carousel-control-prev-icon" aria-hidden="true"></span>
              <span className="sr-only">Previous</span>
            </a>
            <a className="carousel-control-next" href="#myCarousel" role="button" data-slide="next">
              <span className="carousel-control-next-icon" aria-hidden="true"></span>
              <span className="sr-only">Next</span>
            </a>
          </div>
        </div>
      </div>
      <br></br>

      {/* Rest of your content */}
      <center>
        <h1>Intelligent Farming - Real-Time Data Insights</h1>
      </center>
      <br></br>
      <div className="">
        <video className="w-100 h-auto" muted loop autoPlay id="myVideo">
          <source src="/videos/realtime_data.mp4" type="video/mp4"></source>
        </video>
      </div>
      {/* Original row for "Weed Detection" card */}
      <div className="row justify-content-center mt-4">
  <div className="col-md-4 d-flex justify-content-left justify-items-center">
    <div className="card" style={{ marginBottom: '20px', backgroundColor: 'black', color: 'white' }}>
      <img src="/images/weed_detection.gif" alt="weed detection" className="card-img-top" style={{ width: '100%', height: 'auto' }} />
      <div className="card-body">
        <h5 className="card-title">Weed Detection</h5>
        <p className="card-text">{smartFarm.description}</p>
      </div>
    </div>
  </div>
  <div className="col-md-4 d-flex justify-content-left justify-items-center">
    <div className="card" style={{ marginBottom: '20px', backgroundColor: 'black', color: 'white' }}>
      <img src="/images/crop_disease.gif" alt="crop disease detection" className="card-img-top" style={{ width: '100%', height: 'auto' }} />
      <div className="card-body">
        <h5 className="card-title">Crop Disease Detection</h5>
        <p className="card-text">{smartFarm.description1}</p>
      </div>
    </div>
  </div>
</div>


    </div>
  );
};

export default SmartFarming;
