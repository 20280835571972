import React, { useEffect } from 'react';
import Visualization from '../Visualizations/Visualizations';
import { smartLogistics } from '../../data';

const SmartLogistics: React.FC = () => {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  }, []);
  return (
    <div className="container-fluid p-0 mt-4 overflow-hidden">
      <div className="mb-4">
        <img src="/images/smart_logistics 2.jpg" alt="Smart Logistics Banner" className="heroImage img w-100 sl" />
      </div>
      <div className="row justify-content-center">
        <div className="col-md-12 d-flex justify-content-center">
          <div className="header-container">
            <h1 className="header-title">Smart Logistics</h1>
            <p style={{ textAlign: 'center' }}>Real-time Demonstrator</p>
          </div>
        </div>
      </div>
      <div className="container-fluid d-flex justify-content-center align-items-center">
        <div style={{ width: '70%', border: '1px solid white' }}>
          <video className="w-100 h-auto" muted loop autoPlay id="myVideo1">
            <source src="/videos/Logistics.mp4" type="video/mp4" />
          </video>
        </div>
      </div>

      <center><h1>Data Visualization</h1></center>
      <div className="container-fluid d-flex justify-content-center align-items-center">
        <div style={{ width: '70%', border: '1px solid white' }}>
          <video className="w-100 h-auto" muted loop autoPlay id="myVideo1">
            <source src="/videos/gateway.mp4" type="video/mp4" />
          </video>
        </div>
      </div>

      <div className="container-fluid d-flex justify-content-center align-items-center">
        <div style={{ width: '70%', border: '1px solid white' }}>
          <video className="w-100 h-auto" muted loop autoPlay id="myVideo2">
            <source src="/videos/temp_hum.mp4" type="video/mp4" />
          </video>
        </div>
      </div>

      <div className="container-fluid d-flex justify-content-center align-items-center">
        <div style={{ width: '70%', border: '1px solid white' }}>
          <video className="w-100 h-auto" muted loop autoPlay id="myVideo3">
            <source src="/videos/beacon.mp4" type="video/mp4" />
          </video>
        </div>
      </div>

      <div className="row justify-content-center mt-4">
      <div className="col-md-4 d-flex justify-content-left justify-items-center">
        <div className="card" style={{ marginBottom: '20px' }}>
          <img src="/images/label_defect.gif" alt="Label defect detection" className="card-img-top" style={{ height: '400px' }} />
          <div className="card-body">
            <h5 className="card-title">{smartLogistics.title}</h5>
            <p className="card-text">{smartLogistics.description}</p>
          </div>
        </div>
      </div>
    </div>

    </div>

  );
};

export default SmartLogistics;
