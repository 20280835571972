import React, { useEffect, useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import { emailjsIds } from "../../data";

const ContactPage: React.FC = () => {
  const form = useRef<HTMLFormElement>(null);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const backgroundImg1 = '/images/contactus.jpg';

  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  }, []);

  const sendEmail = (e: React.FormEvent) => {
    e.preventDefault();

    emailjs.sendForm(emailjsIds.EMAILJS_SERVICE_ID, emailjsIds.EMAILJS_TEMPLATE_ID, form.current!, emailjsIds.EMAILJS_PUBLIC_KEY)
      .then((result) => {
        setIsSubmitted(true);
      })
      .catch((error) => {
        console.log(error.text);
      });
  };

  return (
    <div style={{ position: 'relative' }}>
      <div
        style={{
          backgroundImage: `url(${backgroundImg1})`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          minHeight: '650px',
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
        }}
      >
        <div className="container-fluid" style={{ minHeight: '100px' }}>
          <div className="row justify-content-center align-items-center" style={{ minHeight: '100px' }}>
            <div className="col-md-12 text-center">
              <h2 style={{ color: '#FFFFFF' }}>Get in touch with us for project development, building business partnerships and anything technology-related</h2>
              <p style={{ color: '#FFFFFF' }}>We are eager to share our expertise with you.</p>
              <p style={{ color: '#FFFFFF' }}>Fill in the below form to get along with us! It becomes a lot more amazing when we get together.</p>
            </div>
          </div>
        </div>
      </div>
      <div style={{ color: 'white', maxWidth: '800px', margin: '100px auto 0', padding: '10px', position: 'relative', zIndex: 1 }}>
        {isSubmitted ? (
          <div className="alert alert-success" role="alert">
            Thank you for contacting us! We will get back to you as soon as possible.
          </div>
        ) : (
          <div>
            <div style={{ marginBottom: '150px'}}></div>
            <form ref={form} onSubmit={sendEmail}>
              <div className="mb-3" style={{ textAlign: 'left' }}>
                <label htmlFor="name" className="form-label">Name</label>
                <input type="text" className="form-control" id="name" name="name" required />
              </div>
              <div className="mb-3" style={{ textAlign: 'left' }}>
                <label htmlFor="email" className="form-label">Email</label>
                <input type="email" className="form-control" id="email" name="email" required />
              </div>
              <div className="mb-3" style={{ textAlign: 'left' }}>
                <label htmlFor="country" className="form-label">Country</label>
                <input type="text" className="form-control" id="country" name="country" required />
              </div>
              <div className="mb-3" style={{ textAlign: 'left' }}>
                <label htmlFor="message" className="form-label">Message</label>
                <textarea className="form-control" id="message" name="message" required></textarea>
              </div>
              <button type="submit" className="btn btn-primary">Submit</button>
            </form>
          </div>
        )}
      </div>

  <div className="container-fluid" style={{ backgroundColor: 'white', width: '100%', margin: '50px auto', padding: '20px', position: 'relative', zIndex: 1, marginTop: '27px', color: 'black'}}>
  <h2 style={{ textAlign: 'center' }}>Our Offices</h2>
  <div className="row">
    <div className="col-md-4">
      <div className="card text-left">
        <div className="card-body" style={{ backgroundColor: 'white'}}>
          <img src="/images/india.png" alt="India Office" style={{ maxWidth: '10%', marginBottom: '10px' }} />
          <p className="card-text">
            <b>India</b><br />
            <b>Hyderabad, Telangana</b><br />
            10-111/1,11,12, NBR Complex, Nadergul, Balapur<br />
            R.R Dist, 501510
          </p>
          <p className="card-text">
            Email: sales@zukunftrobots.com<br></br>
                   hr@zukunftrobots.com
          </p>
          <p className="card-text">
            Tel: +91-9908528526, +91-9248302417
          </p>
        </div>
      </div>
    </div>
    {/* <div className="col-md-4">
      <div className="card text-left">
        <div className="card-body" style={{ backgroundColor: 'white'}}>
          <img src="/images/Germany.png" alt="Germany Office" style={{ maxWidth: '10%', marginBottom: '10px' }} />
          <p className="card-text">
            <b>Germany</b><br />
            <b>coming soon</b><br />
          </p>
        </div>
      </div>
    </div> */}
  </div>
</div>

    </div>
  );
};

export default ContactPage;
