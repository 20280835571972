import React from 'react';
import { Link } from 'react-router-dom';

const Recognitions: React.FC<{ imageUrl: string }> = ({ imageUrl }) => {
  const containerStyle: React.CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: '20px',
    background: 'white', // 3D effect using a gradient
  };

  const headerStyle: React.CSSProperties = {
    textAlign: 'left', // Align text to the left
    marginBottom: '10px',
    color:'black'
  };

  return (
    <div className="digital-twin-page" style={{ backgroundColor: '#000000' }}>
    <div style={containerStyle}>
      <h2 style={headerStyle}>Recognized by</h2>
      <img src={imageUrl} alt="startup india" style={{ maxWidth: '100%', maxHeight: '100%' }} />
    </div>

    <section className="cta">
        <div className="container" style={{ color: 'white' }}>
          <h2>Transform Your Business with our Services</h2>
          <p>Contact us to learn more about our solutions and how they can drive innovation and efficiency in your industry.</p>
          <button className="btn btn-primary" style={{ background: 'linear-gradient(45deg, #007bff, #00bfff)', border: 'none', transition: 'background 0.3s' }}><Link to={`/service/contact`} className="text-white text-decoration-none">
          Contact Us
          </Link></button>
        </div>
      </section>
    </div>
  );
};

export default Recognitions;
