import React from 'react';
import './AdvancedAnalytics.css'; // Import CSS file for styling
import { Link } from 'react-router-dom';

const AdvancedAnalytics: React.FC = () => {

  const advantages = [
    {
      image: '/images/efficiency.png',
      heading: 'Enhanced Predictive Analytics',
      text: 'AI-driven predictive analytics models analyze vast amounts of data to forecast trends, patterns, and future events with higher accuracy and efficiency.',
    },
    {
      image: '/images/precision.png',
      heading: 'Improved Decision Making',
      text: 'AI-powered analytics provide insights and recommendations based on complex data patterns, enabling informed and data-driven decision-making across various domains.',
    },
    {
      image: '/images/scale.png',
      heading: 'Scalable Data Analysis',
      text: 'AI algorithms can efficiently process large datasets, enabling scalable data analysis and exploration of complex relationships within the data.',
    },
    {
      image: '/images/cost.png',
      heading: 'Cost Savings',
      text: 'AI-driven analytics streamline processes and optimize resource allocation, leading to cost savings through increased operational efficiency and reduced waste.',
    },
    {
      image: '/images/space.png',
      heading: 'Customized Solutions',
      text: 'AI-powered analytics solutions can be tailored to specific business needs and objectives, providing customized insights and recommendations for better outcomes.',
    }
];


  return (
    <div className="advanced-analytics-page">
        <div className='  '>
        <video className='w-100 h-auto' muted loop autoPlay id="myVideo">
        <source src="/videos/advanced_analytics.mp4" type="video/mp4"></source>
        </video>
    </div>
      {/* Introduction Section */}
      <section className="introduction">
        <div className="container">
          <h1>Advanced Analytics for Sensor Data</h1>
          <p>
            Our advanced analytics solutions enable you to derive actionable insights from sensor data, empowering you to make informed decisions and optimize your operations.
          </p>
        </div>
      </section>

      {/* How It Works Section */}
      <div className="container mt-4" style={{ backgroundColor: '#ffffff', paddingBottom:'10px' }}>
        <div className="row justify-content-center">
        <h2 style={{ color: 'black' }}>Benefits of Advanced Analytics </h2>
          {advantages.map((advantage, index) => (
            <div className="col-lg-3 col-md-6 col-sm-12 mb-4" key={index}>
              <div className="card h-100" style={{ backgroundColor: '#ffffff' }}>
              <img className="card-img-top w-25 h-25" src={advantage.image} alt={`Advantage ${index + 1}`} style={{ width: '50px', height: '50px', display: 'block', margin: 'auto' }} />
                <div className="card-body">
                  <h4 className="card-title font-weight-bold" style={{ color: 'black' }}>{advantage.heading}</h4>
                  <p className="card-text" style={{ color: 'black' }}>{advantage.text}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <br></br>

      {/* Call-to-Action Section */}
      <section className="cta">
        <div className="container">
          <h2>Unlock Insights with Advanced Analytics</h2>
          <p>Contact us to learn more about our advanced analytics solutions and how they can drive value for your business.</p>
          <button className="btn btn-primary" style={{ background: 'linear-gradient(45deg, #007bff, #00bfff)', border: 'none', transition: 'background 0.3s' }}><Link to={`/service/contact`} className="text-white text-decoration-none">
          Contact Us
          </Link></button>
        </div>
      </section>
    </div>
  );
};

export default AdvancedAnalytics;
