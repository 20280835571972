import React, { useEffect } from 'react';

const Agrivikas: React.FC = () => {
  const backgroundImg2 = '/images/our_vision.jpg';

  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  return (
    <div className='container-fluid h-50 service'>
      {/* Heading */}
      <h1 className='text-center mb-4'>Elevate Farming Practices using Agrivikas</h1>

      {/* Video Introduction */}
      <div className='w-100 h-20 border border-black video'>
        <video className='w-100' muted loop autoPlay id="myVideo">
          <source src="/videos/agrivikas_intro.mp4" type="video/mp4"></source>
        </video>
      </div>

      {/* Agrivikas Overview */}
      <div style={{ paddingTop: '0px' }}>
        <div className="container-fluid" style={{ backgroundImage: `url(${backgroundImg2})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat', minHeight: '350px', marginBottom: '0.1px'}}>
          <div className="row justify-content-center align-items-center" style={{ minHeight: '350px' }}>
            <div className="col-md-12 text-center">
              <h1 style={{ color: 'white', textAlign: 'center'}}>Agrivikas - A Modular Robot</h1>
              <h4 style={{ color: '#000000' }}>Welcome to Agrivikas, where cutting-edge technology meets the age-old art of farming. Our mission is to revolutionize agriculture, introducing a harmonious blend of innovation, efficiency, and sustainability. Explore the future of farming with Agrivikas!</h4>
              <p style={{ color: '#000000' }}>It's not just a robot; it's an experience. Explore the functions in action and witness the artistry of precision farming</p>
            </div>
          </div>
        </div>
      </div>


      <div style={{ backgroundColor: '#cccccc', padding: '1px'}}>
        <h1 style={{ color: '#000000', padding: '10px' }} className='text-center mb-4'>Agrivikas Features
        </h1>
    </div>


      {/* Individual Function Sections */}

      {/* Section a: Weed Picking */}
      <section className="function-section" style={{ backgroundColor: '#d9d9d9', padding: '40px 0', color: '#000000'}}>
        <div className="container">
          <div className="row">
          <div className="col-md-6">
            <video className='w-100' muted loop autoPlay id="myVideo" style={{ borderRadius: '10%', overflow: 'hidden' }}>
                <source src="/videos/weed_picking.mp4" type="video/mp4"></source>
            </video>
            </div>

            <div className="col-md-6" style={{ textAlign: 'left' }}>
              <h1>Intelligent Weed Picking</h1>
              <ul className="key-features" >
              <li>Targeted Weed Detection</li>
              <li>Gentle on Crops</li>
              <li>Adaptive Algorithms</li>
            </ul>
              <p>Say goodbye to invasive weeds with precision and efficiency. Our robot identifies and targets weeds with unparalleled accuracy. Precision picking without harm to surrounding crops. Evolving strategies for diverse weed landscapes.</p>
            </div>
          </div>
        </div>
      </section>

      {/* Section b: Spraying */}
    <section className="function-section" style={{ backgroundColor: '#d9d9d9', padding: '40px 0', color: '#000000', textAlign: 'left'}}>
    <div className="container">
        <div className="row">
        <div className="col-md-6">
            <h1>Precision Spraying</h1>
            <ul className="key-features">
            <li>Targeted Application</li>
            <li>Variable Intensity</li>
            <li>Intelligent Navigation</li>
            </ul>
            <p>Precision spraying for optimal crop health. Pinpoint accuracy in pesticide or fertilizer application. Adjust spraying intensity based on crop needs. Navigate fields efficiently for comprehensive coverage.</p>
        </div>

        <div className="col-md-6">
            <video className='w-100' muted loop autoPlay id="myVideo" style={{ borderRadius: '20%', overflow: 'hidden', textAlign: 'left'}}>
            <source src="/videos/spraying.mp4" type="video/mp4"></source>
            </video>
        </div>
        </div>
    </div>
    </section>

    {/* Section c: Digging */}

    <section className="function-section" style={{ backgroundColor: '#d9d9d9', padding: '40px 0', color: '#000000' , textAlign: 'left'}}>
    <div className="container">
        <div className="row">
        <div className="col-md-6">
            <video className='w-100' muted loop autoPlay id="myVideo" style={{ borderRadius: '30%', overflow: 'hidden' }}>
            <source src="/videos/digging.mp4" type="video/mp4"></source>
            </video>
        </div>

        <div className="col-md-6">
            <h1>Precision Digging</h1>
            <ul className="key-features">
            <li>Efficient Water Passage Digging</li>
            <li>Precision Digging around Trees</li>
            <li>Adaptive Depth Control</li>
            </ul>
            <p>Transforming soil preparation with power and finesse. Efficiently dig passages for water or around trees with surgical precision. Adjust digging depth as needed for various agricultural needs.</p>
        </div>
        </div>
    </div>
    </section>


    {/* Section d: Grass Cutting */}
    <section className="function-section" style={{ backgroundColor: '#d9d9d9', padding: '40px 0', color: '#000000', textAlign: 'left' }}>
    <div className="container">
        <div className="row">
        <div className="col-md-6">
            <h1>Precision Grass Cutting</h1>
            <ul className="key-features">
            <li>Uniform Cutting</li>
            <li>Terrain Adaptability</li>
            <li>Smart Navigation</li>
            </ul>
            <p>Lawn maintenance meets innovation. Achieve a manicured lawn with uniform grass length. Navigate diverse terrains for consistent cutting. Avoid obstacles for a seamless cutting experience.</p>
        </div>

        <div className="col-md-6">
            <video className='w-100' muted loop autoPlay id="myVideo" style={{ borderRadius: '30%', overflow: 'hidden' }}>
            <source src="/videos/grass_cutting.mp4" type="video/mp4"></source>
            </video>
        </div>
        </div>
    </div>
    </section>

    {/* Section e: Ploughing */}
<section className="function-section" style={{ backgroundColor: '#d9d9d9', padding: '40px 0', color: '#000000', textAlign: 'left' }}>
  <div className="container">
    <div className="row">
      <div className="col-md-6">
        <video className='w-100' muted loop autoPlay id="myVideo" style={{ borderRadius: '50%', overflow: 'hidden' }}>
          <source src="/videos/ploughing.mp4" type="video/mp4"></source>
        </video>
      </div>

      <div className="col-md-6">
        <h1>Precision Ploughing</h1>
        <ul className="key-features">
          <li>Powerful Soil Penetration</li>
          <li>Precision Ploughing</li>
          <li>Adaptive Depth Control</li>
        </ul>
        <p>Transforming soil preparation with power and finesse. Tackle tough soil with robust ploughing capabilities. Prepare fields with surgical precision. Adjust ploughing depth as needed.</p>
      </div>
    </div>
  </div>
</section>


    {/* Section f: Harvesting */}
    <section className="function-section" style={{ backgroundColor: '#d9d9d9', padding: '40px 0', color: '#000000', textAlign: 'left' }}>
    <div className="container">
        <div className="row">
        <div className="col-md-6">
            <h1>Smart Harvesting</h1>
            <ul className="key-features">
            <li>Selective Harvesting</li>
            <li>Speed and Efficiency</li>
            <li>Gentle Handling</li>
            </ul>
            <p>Harvesting made smarter, faster, and more productive. Choose specific crops for a targeted harvest. Swift harvesting without compromising quality. Delicate harvesting for fragile crops.</p>
        </div>

        <div className="col-md-6">
            <video className='w-100' muted loop autoPlay id="myVideo" style={{ borderRadius: '60%', overflow: 'hidden' }}>
            <source src="/videos/harvesting.mp4" type="video/mp4"></source>
            </video>
        </div>
        </div>
    </div>
    </section>




    </div>
  );
};

export default Agrivikas;
