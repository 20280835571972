import React, { useEffect } from 'react';
import { privacyPolicy } from "../../data";

const PrivacyPolicy: React.FC = () => {
  const backgroundImg = '/images/data_privacy.jpg';
  const backgroundImg2 = '/images/data_privacy2.jpg';


  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  }, []);

  return (
    <div className="container-fluid " style={{ paddingTop: '20px' }}>
      <div className="container-fluid" style={{ backgroundImage: `url(${backgroundImg})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat', minHeight: '500px' }}>
        <div className="row justify-content-center align-items-center" style={{ minHeight: '500px' }}>
          <div className="col-md-12 text-center">
            <h2 style={{ color: '#FFFFFF' }}>Data Collection and Usage</h2>
            <p style={{ color: '#FFFFFF' }}>Understand how your data is collected, used, and safeguarded in our privacy policy.</p>
          </div>
        </div>
      </div>
      <div className="container-fluid" style={{ backgroundImage: `url(${backgroundImg2})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat', minHeight: '350px' }}>
        <div className="row justify-content-center align-items-center" style={{ minHeight: '350px' }}>
          <div className="col-md-12 text-center">
            <h1 style={{ color: 'white', textAlign: 'center'}}>{privacyPolicy.title}</h1>
            <h2 style={{ color: '#000000' }}>Ensuring the security of personal information is a duty we hold in high regard, and we anticipate the same level of responsibility from you.</h2>
            <p style={{ color: '#000000' }}>At Zukunft Robots, we are dedicated to transparency and providing choices when it comes to safeguarding the privacy of both our clients and visitors.</p>
          </div>
        </div>
      </div>
      
      <div className="container-fluid" style={{ paddingTop: '50px', marginBottom: '20px', color: 'black', backgroundColor: 'white', padding: '20px' ,textAlign: 'left'}}>
        {privacyPolicy.sections.map((section, index) => (
          <div key={index} style={{ marginBottom: '20px' }}>
            <h2 style={{ fontSize: '1.5rem' }}>{section.title}</h2>
            <ul>
              {section.paragraphs.map((paragraph, pIndex) => (
                <li key={pIndex}>{paragraph}</li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PrivacyPolicy;
