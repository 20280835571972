import React, { useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import visionContent from '../../data'; // Import visionContent from data.tsx

const OurVision: React.FC = () => {
  const { title, content } = visionContent[0];
  const backgroundImg = '/images/our_vision1.jpg';
  const backgroundImg2 = '/images/our_vision.jpg';

  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  }, []);

  return (
    <div>
      {/* Container for Data Collection and Usage */}
      <div style={{ paddingTop: '20px' }}>
        <div className="container-fluid" style={{ backgroundImage: `url(${backgroundImg})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat', minHeight: '500px', textAlign: 'left' }}>
          <div className="row justify-content-center align-items-center" style={{ minHeight: '500px' }}>
            <div className="col-md-12 text-center">
              <h2 style={{ color: '#FFFFFF' }}>Path to Smarter Solutions</h2>
              <p style={{ color: '#FFFFFF' }}>Unleashing the Power of Automation and Precision.</p>
            </div>
          </div>
        </div>
      </div>

      {/* Container for Privacy Policy */}
      <div style={{ paddingTop: '0px' }}>
        <div className="container-fluid" style={{ backgroundImage: `url(${backgroundImg2})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat', minHeight: '350px' }}>
          <div className="row justify-content-center align-items-center" style={{ minHeight: '350px' }}>
            <div className="col-md-12 text-center">
              <h1 style={{ color: 'white', textAlign: 'center'}}>{title}</h1>
              <h2 style={{ color: '#000000' }}>At Zukunft Robots, our unwavering goal is to revolutionize the fields of agriculture and logistics through the integration of cutting-edge robotics and intelligent solutions. </h2>
              <p style={{ color: '#000000' }}>Driven by data-driven insights, we envision a future where efficiency, sustainability, and innovation intersect, transforming industries and shaping a smarter world.</p>
            </div>
          </div>
        </div>
      </div>

      {/* OurVision component */}
      <div className="our-vision" style={{ backgroundColor: 'white', padding: '20px', color: 'black' , textAlign: 'left'}}>
        <Container>
          <Row>
            <Col>
              <div className="text-center">
              </div>
              <div className="vision-content">
                {content.map((section, index) => (
                  <div key={index} className="vision-section">
                    <h3>{section.heading}</h3>
                    <p>{section.text}</p>
                  </div>
                ))}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default OurVision;
