import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header/Header';
import FooterComponent from './components/Footer/Footer';
import Home from './components/Home/Home';
import './App.css';
import { header, Footer } from "./data";
import Service from './components/Service/Service';
import Career from './components/Career/Career';
import BlogPage from './components/BlogPage/blogPage';

const App: React.FC = () => {
  useEffect(() => {
    document.title = 'Zukunft Robots';
    document.querySelector('meta[name="description"]')?.setAttribute('content', 'Zukunft Robots is a forward-thinking startup focused on pushing the boundaries of digital automations and robotics technology. With our team of passionate engineers and developers, we are committed to creating solutions that streamline processes, boost productivity, and drive efficiency for businesses worldwide.');

    const preventRightClick = (e: MouseEvent) => {
      e.preventDefault();
    };

    const preventImageDrag = (e: DragEvent) => {
      e.preventDefault();
    };

    const disableImageContextMenuAndDrag = () => {
      const images = document.getElementsByTagName('img');
      for (let i = 0; i < images.length; i++) {
        images[i].addEventListener('contextmenu', preventRightClick);
        images[i].addEventListener('dragstart', preventImageDrag);
      }
    };

    disableImageContextMenuAndDrag();

    return () => {
      const images = document.getElementsByTagName('img');
      for (let i = 0; i < images.length; i++) {
        images[i].removeEventListener('contextmenu', preventRightClick);
        images[i].removeEventListener('dragstart', preventImageDrag);
      }
    };
  }, []);

  const isJavaScriptEnabled = () => {
    return (
      <noscript>
        Please enable JavaScript to view this website.
      </noscript>
    );
  };

  return (
    <div className='bg-black p-0 m-0 ' >
      <div >
        <Header {...header} />
        <hr className="bg-success w-100 h-100" />
        <div>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="service/:name" element={<Service />} />
            <Route path="careers/:id" element={<Career />} />
            <Route path="blogs/:id" element={<BlogPage />} />
            <Route path="*" element={<Home />} />
          </Routes>
        </div>
        <FooterComponent {...Footer} />
        {isJavaScriptEnabled()}
      </div>
    </div>
  );
};

export default App;
