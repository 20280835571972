import React from 'react';
import './IIot.css'; // Import CSS file for styling
import { Link } from 'react-router-dom';

const IndustrialIoT: React.FC = () => {

  const advantages = [
    {
      image: '/images/efficiency.png',
      heading: 'Enhanced Predictive Maintenance',
      text: 'AI-driven predictive maintenance systems optimize equipment maintenance schedules, reducing downtime and extending asset lifespan. This is particularly crucial in industrial IoT environments where equipment uptime is critical for operations.'
    },
    {
      image: '/images/precision.png',
      heading: 'Optimized Asset Performance',
      text: 'AI models analyze real-time data from industrial equipment, optimizing performance and efficiency to maximize output. In industrial IoT settings, this ensures that assets operate at peak performance levels, enhancing productivity.'
    },
    {
      image: '/images/scale.png',
      heading: 'Improved Product Design and Development',
      text: 'AI-powered analytics on industrial IoT data aid in designing and iterating products faster, meeting evolving market demands. This benefit is essential for industrial IoT applications where rapid prototyping and innovation are necessary for staying competitive.'
    },
    {
      image: '/images/latency.png',
      heading: 'Low Latency',
      text: 'AI models deployed on edge devices within industrial IoT networks ensure low latency, critical for real-time decision-making and control in manufacturing processes. This is particularly important in industrial IoT environments where timely responses to data are essential for operational efficiency.'
    },
    {
      image: '/images/cost.png',
      heading: 'Reduced Downtime and Costs',
      text: 'AI-driven predictive analytics minimize unplanned downtime, leading to cost savings through optimized resource allocation and maintenance planning. This benefit is invaluable in industrial IoT settings where downtime can result in significant production losses and increased operational costs.'
    }
];


  
  return (
    <div className="industrial-iot-page">
      {/* Video Section */}
      <div className='  '>
        <video className='w-100 h-auto' muted loop autoPlay id="myVideo">
        <source src="/videos/industrial_iot.mp4" type="video/mp4"></source>
        </video>
    </div>

      {/* Introduction Section */}
      <section className="introduction">
        <div className="container">
          <h1>Industrial IoT Solutions</h1>
          <p>
            Industrial IoT solutions leverage connected sensors, devices, and analytics to optimize industrial processes, improve efficiency, and enable predictive maintenance.
          </p>
        </div>
      </section>

      {/* How It Works Section */}
      <div className="container mt-4" style={{ backgroundColor: '#ffffff', paddingBottom:'10px' }}>
        <div className="row justify-content-center">
        <h2 style={{ color: 'black' }}>Benefits of IIOT </h2>
          {advantages.map((advantage, index) => (
            <div className="col-lg-3 col-md-6 col-sm-12 mb-4" key={index}>
              <div className="card h-100" style={{ backgroundColor: '#ffffff' }}>
              <img className="card-img-top w-25 h-25" src={advantage.image} alt={`Advantage ${index + 1}`} style={{ width: '50px', height: '50px', display: 'block', margin: 'auto' }} />
                <div className="card-body">
                  <h4 className="card-title font-weight-bold" style={{ color: 'black' }}>{advantage.heading}</h4>
                  <p className="card-text" style={{ color: 'black' }}>{advantage.text}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <br></br>

      {/* How It Works Section */}
      <section className="how-it-works">
        <div className="container">
          <h2>How Industrial IoT Works</h2>
          <p>
            Industrial IoT systems collect data from sensors and devices installed across the industrial environment. This data is then analyzed in real-time to identify patterns, trends, and anomalies, enabling proactive decision-making and optimization of operations.
          </p>
        </div>
      </section>

      {/* Call-to-Action Section */}
      <section className="cta">
        <div className="container">
          <h2>Optimize Your Industrial Processes with IoT</h2>
          <p>Contact us to explore how Industrial IoT solutions can transform your operations and drive business growth.</p>
          <button className="btn btn-primary" style={{ background: 'linear-gradient(45deg, #007bff, #00bfff)', border: 'none', transition: 'background 0.3s' }}><Link to={`/service/contact`} className="text-white text-decoration-none">
          Contact Us
          </Link></button>
        </div>
      </section>
    </div>
  );
};

export default IndustrialIoT;
