import React from 'react';
import './AiIotCloudServices.css'; // Import CSS file for styling
import { Link } from 'react-router-dom';

const AiIoTCloudServices: React.FC = () => {

  const advantages = [
    {
      image: '/images/scalability.png',
      heading: 'Scalability',
      text: 'Public cloud platforms offer scalable infrastructure, allowing organizations to easily scale AI and IoT services based on fluctuating workloads and data volumes.',
    },
    {
      image: '/images/cost-effectiveness.png',
      heading: 'Cost-effectiveness',
      text: 'Pay-as-you-go pricing models eliminate the need for upfront investment in hardware, making AI and IoT services more accessible and cost-effective for organizations of all sizes.',
    },
    {
      image: '/images/security.png',
      heading: 'Security and Compliance',
      text: 'Robust security measures and compliance certifications provided by public cloud providers protect AI and IoT data, ensuring regulatory compliance and data privacy.',
    },
    {
      image: '/images/managed-services.png',
      heading: 'Managed Services',
      text: 'Cloud providers offer managed AI and IoT services that abstract away infrastructure complexity, allowing organizations to focus on innovation rather than infrastructure management.',
    },
    {
      image: '/images/integration.png',
      heading: 'Integration and Interoperability',
      text: 'Public cloud platforms support seamless integration with other cloud services and third-party tools, facilitating data exchange, interoperability, and collaboration across different services and applications.',
    }
];

  
  return (
    <div className="aiot-cloud-services-page">
        <div className='  '>
            <video className='w-100 h-auto' muted loop autoPlay id="myVideo">
            <source src="/videos/cloud.mp4" type="video/mp4"></source>
            </video>
        </div>
      {/* Introduction Section */}
      <section className="introduction">
        <div className="container">
          <h1>AI and IoT-based Cloud Services</h1>
          <p>
            Our AI and IoT-based cloud services offer scalable and secure solutions for integrating artificial intelligence and internet of things technologies into your applications and systems.
          </p>
        </div>
      </section>

      {/* How It Works Section */}
      <div className="container mt-4" style={{ backgroundColor: '#ffffff', paddingBottom:'10px' }}>
        <div className="row justify-content-center">
        <h2 style={{ color: 'black' }}>Benefits of AI and IOT services from Public Clouds </h2>
          {advantages.map((advantage, index) => (
            <div className="col-lg-3 col-md-6 col-sm-12 mb-4" key={index}>
              <div className="card h-100" style={{ backgroundColor: '#ffffff' }}>
              <img className="card-img-top w-25 h-25" src={advantage.image} alt={`Advantage ${index + 1}`} style={{ width: '50px', height: '50px', display: 'block', margin: 'auto' }} />
                <div className="card-body">
                  <h4 className="card-title font-weight-bold" style={{ color: 'black' }}>{advantage.heading}</h4>
                  <p className="card-text" style={{ color: 'black' }}>{advantage.text}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <br></br>

      {/* Call-to-Action Section */}
      <section className="cta">
        <div className="container">
          <h2>Transform Your Business with AI and IoT</h2>
          <p>Contact us to learn more about our AI and IoT-based cloud services and how they can drive innovation in your business.</p>
          <button className="btn btn-primary" style={{ background: 'linear-gradient(45deg, #007bff, #00bfff)', border: 'none', transition: 'background 0.3s' }}><Link to={`/service/contact`} className="text-white text-decoration-none">
          Contact Us
          </Link></button>
        </div>
      </section>
    </div>
  );
};

export default AiIoTCloudServices;
