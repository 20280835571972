import React, { useEffect } from 'react';
import './Banner.css';
import { Link } from 'react-router-dom';

// ServiceItem component to represent each service
const ServiceItem: React.FC<{ title: string; description: string; image: string }> = ({ title, description, image }) => (
  <div className="service-item">
    <img src={image} alt={title} />
    <h3>{title}</h3>
    <p>{description}</p>
  </div>
);

// Banner component
const Banner: React.FC = () => {
  useEffect(() => {
    // Scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  return (
    <div>
      <div className='w-100 h-25 border border-black video'>
        <img src="/images/spraying.png" alt="Automatic Water Management" className="card-img-top" />
      </div>
      <div className='container-fluid bg-white d-flex align-items-center justify-content-center py-5'>
        <div className='who-we-are p-4'>
          <center><h1>Who We Are</h1></center>
          <p>
            Zukunft Robots is a forward-thinking startup focused on pushing the boundaries of digital automations and robotics technology. With our team of passionate engineers and developers, we're committed to creating solutions that streamline processes, boost productivity, and drive efficiency for businesses worldwide.
          </p>
          <p>
            As a technology-driven organization, Zukunft Robots is committed to pushing the boundaries of what is possible in the manufacturing, logistics, and agriculture industries. By combining the power of cloud computing, IoT, and AI, we strive to deliver transformative solutions that promote sustainability, enhance productivity, and drive operational excellence for our clients.
          </p>
          <p>
            With our commitment to excellence and continuous improvement, Zukunft Robots aspires to be a driving force behind the adoption of cutting-edge technologies. By collaborating with industry leaders and embracing the latest advancements, we are determined to make a significant impact on the future of these sectors, shaping the world for a better and more sustainable tomorrow.
          </p>
          <p>
            We envision a future where industries thrive with the help of robotics and automations. By embracing innovation, businesses can unlock new opportunities, drive growth, and create a more sustainable future.
          </p>
          {/* Add "Interact with Team" button */}
          <center>
            <button className="interact-button">
              <Link to={`/service/contact`} className="text-white text-decoration-none">
                Interact with Team
              </Link>
            </button>
          </center>
        </div>
      </div>

      <div className="container mt-4" style={{ backgroundColor: '#ffffff', paddingBottom:'10px' }}>
      <div className='what-we-offer p-4'>
        <center><h1>What we offer </h1></center>
        <div className="services-grid">
          <a href="/service/IndustrialIoT" style={{ textDecoration: 'none', color: 'black' }}>
            <ServiceItem
              title="Industrial IoT"
              description="Leverage IoT technology to optimize industrial processes and enhance operational efficiency."
              image="/images/industrial_iot.png"
            />
          </a>
          <a href="/service/AiModels" style={{ textDecoration: 'none', color: 'black' }}>
            <ServiceItem
              title="AI Models for Edge Devices"
              description="Deploy AI models on edge devices for real-time decision-making and intelligent automation."
              image="/images/edge_AI.jpg"
            />
          </a>
          <a href="/service/Agrivikas" style={{ textDecoration: 'none', color: 'black' }}>
            <ServiceItem
              title="User-Friendly Robots"
              description="Design and deploy robots that are intuitive to use and seamlessly integrate into existing workflows."
              image="/images/robotics.jpg"
            />
          </a>
          <a href="/service/DigitalTwin" style={{ textDecoration: 'none', color: 'black' }}>
            <ServiceItem
              title="Digital Twins"
              description="Create digital replicas of physical assets to simulate and optimize performance in virtual environments."
              image="/images/digital_twin.jpg"
            />
          </a>
          <a href="/service/AdvancedAnalytics" style={{ textDecoration: 'none', color: 'black' }}>
            <ServiceItem
              title="Advanced Analytics for Sensor Data"
              description="Utilize advanced analytics techniques to derive actionable insights from sensor data and drive informed decision-making."
              image="/images/advancedanalytics.jpg"
            />
          </a>
          <a href="/service/AiIoTCloudServices" style={{ textDecoration: 'none', color: 'black' }}>
            <ServiceItem
              title="IoT and AI Cloud Services"
              description="Leverage cloud-based solutions to integrate, manage, and analyze IoT data, enabling AI-powered insights and informed decision-making."
              image="/images/AI&IOT.png"
            />
          </a>
        </div>
      </div>
    </div>



    </div>
  );
};

export default Banner;
