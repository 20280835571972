import {roleNres, aboutData} from "../../data";
import React, { useEffect, useRef } from 'react';
import emailjs from '@emailjs/browser';
import { useParams } from "react-router-dom";

function Career() {

  const form = useRef<any>()
  const params = useParams()
  const sendEmail = (e:any) => {
    e.preventDefault();

    emailjs.sendForm('service_bd6bedh', 'template_2mtap1q', form.current, 'ACRWiBINsSsdAwAYL')
      .then((result:any) => {
          console.log('success');
      }, (error:any) => {
          console.log(error.text);
      });
  };
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  }, []);

  return (
    <div className='container text-white service'>
      <div className="row justify-content-center" style={{ paddingTop: "40px", textAlign:'left'}}> 
        <div className='col-md-6'>
          {
            roleNres.map( (item:any,index:number)=>
            {
              if(params.id == item.id){
              return (
                <div>
                <p >{item.subtitle}</p>
                    <h1>{item.title}</h1>
                    {
                      item.descriptions && item.descriptions.map(  (des:any,index:number)=>
                      <div className='m-2' >
                        <h5>{des.title}</h5>
                        <div className='col-md-12 d-flex'> 
                        <ul >
                        {
                          des.responsibilties && des.responsibilties.map( (point:any) => <li className='text-white'>{point}</li>)
                        }
                        </ul>
                        </div>
                      </div>
                      )
                    }
                </div>
              )   
              }
            }
            )
          }
          <div className="row justify-content-start"> {/* Updated line */}
        <div className="col-md-6">
          <button type="button" className="cust-btn rounded-pill" 
          style={{
            background: "linear-gradient(45deg, #006400, #003300)",
            border: "none",
            color: "white",
            transition: "background-color 0.3s ease",
          }}
           data-bs-toggle="modal" data-bs-target="#exampleModal">
            Apply Now
          </button>
        </div>
      </div>
      </div>
      <div className='col-md-3 mt-4 border border-white' style={{ backgroundColor: '#C7F2DD', color: 'black' }}>
        <h3>{aboutData.title}</h3>
        {aboutData.paragraphs.map((paragraph, index) => (
          <p key={index}>{paragraph}</p>
        ))}
        <p><a href={aboutData.website}>{aboutData.website}</a></p>
      </div>
      </div>
      <div className="modal fade" id="exampleModal" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog" role="document">
    <div className="modal-content" style={{ backgroundColor: "#000000", color: "#ffffff" }}>
      <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLabel">Join our team</h5>
        <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body d-flex justify-content-center align-items-center flex-column">
        <div className="mb-3 text-center">
          <p style={{ margin: 0 }}>Thank you for your interest, just send us your resume to below mail id</p>
          <a style={{ color: "green", textDecoration: "none" }} href="mailto:hr@zukunftrobots.com">hr@zukunftrobots.com</a>
        </div>
      </div>
    </div>
  </div>
</div>

    </div>
  )
}

export default Career
