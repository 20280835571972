import React, { useEffect } from 'react';

const B100: React.FC = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
  };

  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  }, []);

  return (
      <div className='container-fluid h-50 service'>        
        <div className='w-100 h-100'>
          <img className='w-100 h-auto' src="/images/B100.png" alt="B100 Image" />
          <img className='w-100 h-auto' src="/images/br100.png" alt="B100 Image" />
        </div>
      </div>
  );
};

export default B100;