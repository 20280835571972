import React from 'react';
import { Link } from 'react-router-dom';

interface CardComponentProps {
  carouselImages?: any[] | undefined;
}

const CardComponent: React.FC<CardComponentProps> = ({ carouselImages }) => {

  if (!carouselImages)
    return <div></div>

  return (
    <div className='container-lg'>
      <h2 style={{ color: '#ffffff', textAlign: 'center', marginTop: '20px' }}>Cutting Edge Technologies Powering Our Innovations</h2>
      <div className="row px-3 ">
        {
          carouselImages.map((item: any, index) => (
            <div className={`card cursor-pointer p-0 col-md mx-1 md-1 my-4`} key={index}>
              <img className="card-img-top h-100" src={item.image.src} alt="Card image cap" style={{ objectFit: 'cover' }} />
              <div className="card-body">
                <h5 className="card-title px-2">{item.title}</h5>
                <p className="card-text px-2 py-3">{item.descripton}</p>
              </div>
            </div>
          ))
        }
      </div>
    </div>
  );
};

export default CardComponent;
