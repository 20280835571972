import React, { useEffect, useState } from 'react';
import { blogs } from "../../data";
import { useNavigate } from 'react-router-dom';

const Blog: React.FC = () => {
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  }, []);

  const [contents, setContent] = useState<any>([]);

  function chunkArray(arr: any, chunkSize: any) {
    const chunks = [];
    for (let i = 0; i < arr.length; i += chunkSize) {
      chunks.push(arr.slice(i, i + chunkSize));
    }
    return chunks;
  }

  function truncateString(str: any, maxLength: any) {
    if (str.length > maxLength) {
      return str.slice(0, maxLength) + '...';
    }
    return str;
  }

  useEffect(() => {
    setContent(chunkArray(blogs, 3));
  }, []);

  return (
    <div className="container" style={{ width: "100%", marginTop: "100px" }}>
      <div className='row'>
        {blogs.map((blog: any, index: any) => (
          <div className="col-md-4 zoom-out-element" style={{ color: 'white', marginBlock: "20px"}} onClick={() => {
            navigate(`/blogs/${index}`);
          }}>
            <div className="card" style={{ width: "100%", cursor: "pointer" }}>
              <img className="card-img-top" src={blog?.image} alt="Card image cap" style={{ width: "auto", height: "auto", objectFit: "cover" }} />
              <div className="card-body">
                <h5 className="card-title">{blog.title}</h5>
                <p className="card-text">{truncateString(blog.content, 100)}</p>
                <div className='d-flex flex-row'>
                  <img className="rounded-circle" style={{ height: '20px', width: '20px' }} alt="avatar1" src="/images/Harish2.JPG" />
                  <div style={{ marginLeft: "10px" }}>
                    <div className="card-text">{blog.author}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Blog;
