import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
import { portfolio } from "../../data";
import { Link } from 'react-router-dom';



const OurTeam: React.FC = () => {
  const backgroundImg = '/images/join_team.jpg'; // Replace with the actual path to your background image
  const backgroundImg1 = '/images/join_team1.jpg';

  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  }, []);

  const [hoveredCardIndex, setHoveredCardIndex] = useState<number | null>(null);

  return (
    <div> 

      <div className="container-fluid" style={{ backgroundImage: `url(${backgroundImg1})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat', minHeight: '400px' }}>
        <div className="row justify-content-center align-items-center" style={{ minHeight: '400px' }}>
          <div className="col-md-12 text-center">
            <h2 style={{ color: '#FFFFFF' }}>At Zukunft Robots Its Not Just About The Company</h2>
            <p style={{ color: '#FFFFFF' }}>brilliant minds and passionate souls that drive us forward. Our success is a testament to the dedicated individuals who work tirelessly to make a difference.</p>
            <button className="btn btn-primary" style={{ background: 'linear-gradient(45deg, #FFA500, #FF4500)', border: 'none', transition: 'background 0.3s' }}><Link to={`/service/contact`} className="text-white text-decoration-none">
            Get in touch with our team
          </Link></button>
          </div>
        </div>
      </div>

      <div className="container-fluid" style={{ backgroundColor: '#f2f2f2', paddingTop: '40px', paddingBottom: '20px' }}>
        <div className="row justify-content-center align-items-center">
          <div className="col-md-8 text-center">
            <h2 style={{ color: '#000000', fontSize: '2.5rem', fontWeight: 'bold', marginBottom: '1rem' }}>Transforming Agriculture, Manufacturing, and Logistics with Robotics and Digital Transformation Services</h2>
            <p style={{ color: '#000000', fontSize: '1.2rem' }}>
              We are a startup robotics company revolutionizing agriculture, manufacturing, and logistics. Our smart solutions empower farmers, manufacturers, and logistics providers to optimize operations, increase productivity, and reduce costs. With a team of experts, we create cutting-edge robots and provide digital transformation services, shaping the future of these industries.
            </p>
            <p style={{ color: '#000000', fontSize: '1.2rem', alignContent:'left'}}>Join us in transforming the way we cultivate crops, manufacture products, and manage supply chains, making them more efficient, sustainable, and technologically advanced.</p>
          </div>
        </div>
      </div>

      <div className="container-fluid" style={{ paddingTop: '20px', backgroundColor: '#ffffff' }}>
        <h1 className="mt-4 text-center" style={{ color: '#000000' }}>Core Team</h1>
        <h3 className="mt-4 text-center" style={{ color: '#000000' }}>Meet our team members</h3>
        <div className="row justify-content-center align-items-center">
          {portfolio.map((member, index) => (
            <div key={index} className="col-md-4 text-center">
              <div
                className="card mb-4"
                style={{
                  cursor: 'pointer',
                  backgroundColor: hoveredCardIndex === index ? 'rgba(66, 135, 203, 0.8)' : '#ffffff',
                  transition: 'background-color 0.3s'
                }}
                onMouseEnter={() => setHoveredCardIndex(index)}
                onMouseLeave={() => setHoveredCardIndex(null)}
              >
                <div className="card-body">
                  <div className="rounded-circle overflow-hidden mx-auto d-flex align-items-center justify-content-center" style={{ width: "150px", height: "150px", backgroundColor: "black" }}>
                  <img src={member.picture} alt={member.name} className="img-fluid" style={{ borderRadius: '50%' }} />
                  </div>
                  <h2 className="card-title" style={{ color: hoveredCardIndex === index ? '#ffffff' : '#000000' }}>{member.name}</h2>
                  <h5 className="card-subtitle mb-2 text-muted" style={{ color: hoveredCardIndex === index ? '#ffffff' : '#000000' }}>{member.role}</h5>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="container-fluid" style={{ backgroundImage: `url(${backgroundImg})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat', minHeight: '400px' }}>
        <div className="row justify-content-center align-items-center" style={{ minHeight: '400px' }}>
          <div className="col-md-12 text-center">
            <h2 style={{ color: '#FFFFFF' }}>Ready for a fulfilling and rewarding career opportunity?</h2>
            <p style={{ color: '#FFFFFF' }}>We're eager to connect with like-minded individuals who share a passion for technology and turning our clients' visions into thriving business ventures.</p>
            <button className="btn btn-primary" style={{ background: 'linear-gradient(45deg, #007bff, #00bfff)', border: 'none', transition: 'background 0.3s' }}><Link to={`/service/careers`} className="text-white text-decoration-none">
            Join our Team
          </Link></button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurTeam;
