
import { Button, Form, FormGroup, Input } from "reactstrap"; // import Button, Form, FormGroup, and Input components from Reactstrap
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"; // import FontAwesomeIcon component from the @fortawesome/react-fontawesome library
import './Footer.css'
import { Link } from 'react-router-dom';
import { useEffect } from "react";
interface FooterComponentProps {
  links? : any[] | undefined;
  socialMedia? : any[] | undefined;
  objectives? : any[] | undefined;
}// define an empty object type for the props that the Footer component may receive

const FooterComponent: React.FC<FooterComponentProps> = ({links, socialMedia, objectives}) => { // define the Footer component as a functional component that returns JSX markup for the footer
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  }, []);
  return (
    <div className="bg-black container-lg text-white" >
      <div className="row mt-5  ">
          <div className="col-lg-3 col-sm-4">
              <ul className="list-unstyled">
                  {links && links.map((link:any,index:number)=>(
                      <li className="list-group-item pb-2 cursor" key={index}>
                        <Link to={`service/${link.href}`} className="list-group-item pb-2 cursor" aria-current="page">{link.title}</Link>
                        </li>
                  ))}
              </ul>
          </div>
          <hr className="hr bg-white d-sm-none" />
          <div className="col-lg-3 col-sm-4">
              <ul className="list-inline"> {/* the unordered list with inline styling */}
                {
                  objectives && objectives.map((objective,index)=>(
                    <li className="list-unstyled" key={index} > {/* the first list item */}
                      <Link to={`service/${objective.href}`} className="list-group-item pb-2 cursor" aria-current="page">{objective.title}</Link>
                    </li>
                  ))
                }
                </ul>
          </div>
          <hr className="hr bg-white d-sm-none" />
          <div className="col-lg-3 col-sm-4">
          <h3>Connect us on</h3> {/* the heading */}
            <div className="row">
              <ul> {/* the unordered list with inline styling */}
              {
                socialMedia && socialMedia.map((medium,index)=>(
                  <li className="list-inline me-2 mb-2"> {/* the first list item */}
                    <a  className="links" href={`${medium.link}`} target="_blank" rel="noreferrer" > {/* the Facebook link */}
                      <FontAwesomeIcon icon={medium.icon} /> {/* the Facebook icon */}
                      {medium.title}
                    </a>
                  </li>
                ))
              }
              </ul>
            </div>
          </div>
          <hr className="hr bg-white d-lg-none" />
          <div className="col-lg-3 col-sm-12 py-md-3 p-lg-0">
              <h3>Subscribe to our newsletter</h3> {/* the heading */}
              <Form> {/* the form */}
                <FormGroup> {/* the form group */}
                  <Input type="email"  name="email" placeholder="Enter your email address" /> {/* the input field */}
                </FormGroup>
                <Button  style={{
                  background: "linear-gradient(45deg, #006400, #003300)",
                  border: "none",
                  color: "white",
                  transition: "background-color 0.3s ease",
                }} 
                className="rounded-pill"
                color="success" type="submit"> {/* the submit button */}
                  Subscribe
                </Button>
              </Form>
          </div>
      </div>
      <hr className=" bg-white" />
          <div className="text-center py-2">
            © 2024 Zukunft Robots Pvt Ltd. All rights reserved.
          </div>
    </div>
     );
};

export default FooterComponent;
