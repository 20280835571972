import React from 'react';
import './DigitalTwin.css'; // Import CSS file for styling
import { Link } from 'react-router-dom';

const DigitalTwin: React.FC = () => {

  const advantages = [
    {
      image: '/images/efficiency.png',
      heading: 'Enhanced Predictive Maintenance',
      text: 'Digital twins enhance predictive maintenance across various industries, optimizing asset performance and reducing downtime.',
    },
    {
      image: '/images/precision.png',
      heading: 'Optimized Asset Performance',
      text: 'Digital twins ensure precision in asset performance, facilitating accurate operations and improving productivity.',
    },
    {
      image: '/images/scale.png',
      heading: 'Improved Product Design and Development',
      text: 'Digital twins enable improved product design and development processes, fostering innovation and adaptability.',
    },
    {
      image: '/images/space.png',
      heading: 'Real-time Monitoring and Control',
      text: 'Digital twins provide real-time monitoring and control capabilities, allowing for proactive decision-making and efficiency enhancement.',
    },
    {
      image: '/images/cost.png',
      heading: 'Reduced Downtime and Costs',
      text: 'The implementation of digital twins leads to reduced downtime and operational costs, optimizing resource utilization and improving overall efficiency.',
    }
  ];
  
  return (
    <div className="digital-twin-page">
      <div className='  '>
        <video className='w-100 h-auto' muted loop autoPlay id="myVideo">
        <source src="/videos/Digital_Twin.mp4" type="video/mp4"></source>
        </video>
    </div>
      {/* Introduction Section */}
      <section className="introduction" style={{ backgroundColor: '#ffffff' }}>
        <div className="container" >
          <h1>Digital Twin Solutions</h1>
          <p>
            Digital twins are virtual representations of physical objects or systems. They enable businesses to simulate, analyze, and optimize real-world processes and assets, leading to improved performance, efficiency, and innovation.
          </p>
        </div>
      </section>

      {/* How It Works Section */}
      <div className="container mt-4" style={{ backgroundColor: '#ffffff', paddingBottom:'10px' }}>
        <div className="row justify-content-center">
        <h2 style={{ color: 'black' }}>Benefits of Digital Twins </h2>
          {advantages.map((advantage, index) => (
            <div className="col-lg-3 col-md-6 col-sm-12 mb-4" key={index}>
              <div className="card h-100" style={{ backgroundColor: '#ffffff' }}>
                <img className="card-img-top w-25 h-25" src={advantage.image} alt={`Advantage ${index + 1}`} style={{ width: '50px', height: '50px', display: 'block', margin: 'auto' }} />
                <div className="card-body">
                  <h4 className="card-title font-weight-bold" style={{ color: 'black' }}>{advantage.heading}</h4>
                  <p className="card-text" style={{ color: 'black' }}>{advantage.text}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <br></br>

      {/* How It Works Section */}
      <section className="how-it-works" style={{ backgroundColor: '#ffffff', paddingBottom:'10px' }}>
        <div className="container">
          <h2>How Digital Twins Work</h2>
          <p>
            Digital twins utilize IoT sensors, data analytics, and simulation models to create virtual replicas of physical assets or systems. These virtual twins are continuously updated with real-time data, allowing businesses to monitor, analyze, and optimize performance remotely.
          </p>
        </div>
      </section>

      {/* Call-to-Action Section */}
      <section className="cta">
        <div className="container">
          <h2>Transform Your Business with Digital Twins</h2>
          <p>Contact us to learn more about our digital twin solutions and how they can drive innovation and efficiency in your industry.</p>
          <button className="btn btn-primary" style={{ background: 'linear-gradient(45deg, #007bff, #00bfff)', border: 'none', transition: 'background 0.3s' }}><Link to={`/service/contact`} className="text-white text-decoration-none">
          Contact Us
          </Link></button>
        </div>
      </section>
    </div>
  );
};

export default DigitalTwin;
