import React, { useEffect } from 'react';
import { termsConditions } from "../../data";

const TermsAndConditions: React.FC = () => {
  const backgroundImg = '/images/terms&condition.jpg';
  const backgroundImg1 = '/images/terms&condition1.jpg';

  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  }, []);

  return (
    <div className="container-fluid " style={{ paddingTop: '20px' }}>
      <div className="container-fluid" style={{ backgroundImage: `url(${backgroundImg})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat', minHeight: '500px' }}>
        <div className="row justify-content-center align-items-center" style={{ minHeight: '500px' }}>
          <div className="col-md-12 text-center">
            <h2 style={{ color: '#FFFFFF' }}>Your Journey, Our Commitment: Navigating Together with Trust and Clarity</h2>
            <p style={{ color: '#FFFFFF' }}>Read our Terms and Conditions to understand our policies and guidelines for using our services..</p>
          </div>
        </div>
      </div>
      <div className="container-fluid" style={{ backgroundImage: `url(${backgroundImg1})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat', minHeight: '350px' }}>
        <div className="row justify-content-center align-items-center" style={{ minHeight: '350px' }}>
          <div className="col-md-12 text-center">
            <h1 style={{ color: 'white', textAlign: 'center'}}>{termsConditions.title}</h1>
            <h2 style={{ color: '#000000' }}>We value your trust in us and hold the security of personal information in high regard, requesting you to abide by our Terms and Conditions to ensure a safe and trustworthy experience.</h2>
            <p style={{ color: '#000000' }}>At Zukunft Robots, we are dedicated to providing a clear and open framework through our Terms and Conditions, ensuring your rights and privacy are protected as both clients and visitors to our services.</p>
          </div>
        </div>
      </div>
      
      <div className="container-fluid" style={{ paddingTop: '50px', paddingLeft: '70px',marginBottom: '20px', color: 'black', backgroundColor: 'white', padding: '20px', textAlign: 'left'}}>
        {termsConditions.sections.map((section, index) => (
          <div key={index} style={{ marginBottom: '20px' }}>
            <h2 style={{ fontSize: '1.5rem' }}>{section.title}</h2>
            <ul>
              {section.paragraphs.map((paragraph, pIndex) => (
                <li key={pIndex}>{paragraph}</li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TermsAndConditions;

