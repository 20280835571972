const initialState = {
    temperature: 0,
    accelerometer: 0,
    waterConsumption: 0,
  };
  
  const reducer = (state = initialState, action: any) => {
    switch (action.type) {
      case 'UPDATE_TEMPERATURE':
        return {
          ...state,
          temperature: action.payload,
        };
      case 'UPDATE_ACCELEROMETER':
        return {
          ...state,
          accelerometer: action.payload,
        };
      case 'UPDATE_WATER_CONSUMPTION':
        return {
          ...state,
          waterConsumption: action.payload,
        };
      default:
        return state;
    }
  };
  
  export default reducer;
  