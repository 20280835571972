import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { blogs } from "../../data";


const BlogPage: React.FC = () => {
  const { id }: any = useParams();

  useEffect(() => {
    console.log(blogs[id])
  }, [])



  if (!blogs[id]) return (<div>No Content</div>)

  return (
    <div className="container" style={{ width: "100%", marginTop: "100px" }}>
      <div style={{ color: '#DCDCDC',fontSize: '40px', marginTop:"20px" }}>{blogs[id]?.title}</div>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '20px' }}>
      <img src={blogs[id]?.image} alt="Article Image" style={{ width: '100%', maxWidth: '500px', height: 'auto' }} />
      </div>
      <div style={{ color: '#DCDCDC',fontSize: '20px', marginTop:"40px" }}>{blogs[id]?.content}</div>
      <div style={{ color: '#FFD700',fontSize: '30px', marginTop:"20px" }}>{blogs[id]?.title1}</div>
      <div style={{ color: '#DCDCDC',fontSize: '20px', marginTop:"20px" }}>{blogs[id]?.content1}</div>
      <div style={{ color: '#FF00FF',fontSize: '30px', marginTop:"20px" }}>{blogs[id]?.title2}</div>
      <div style={{ color: '#DCDCDC',fontSize: '20px', marginTop:"20px" }}>{blogs[id]?.content2}</div>
      <div style={{ color: '#E6E6FA',fontSize: '30px', marginTop:"20px" }}>{blogs[id]?.title3}</div>
      <div style={{ color: '#DCDCDC',fontSize: '20px', marginTop:"20px" }}>{blogs[id]?.content3}</div>
      <div style={{ color: '#00FFFF',fontSize: '30px', marginTop:"20px" }}>{blogs[id]?.title4}</div>
      <div style={{ color: '#DCDCDC',fontSize: '20px', marginTop:"20px" }}>{blogs[id]?.content4}</div>
      <div style={{ color: '#ADD8E6',fontSize: '30px', marginTop:"20px" }}>{blogs[id]?.title5}</div>
      <div style={{ color: '#DCDCDC',fontSize: '20px', marginTop:"20px" }}>{blogs[id]?.content5}</div>
      <div style={{ color: '#FFB6C1',fontSize: '30px', marginTop:"20px" }}>{blogs[id]?.title6}</div>
      <div style={{ color: '#DCDCDC',fontSize: '20px', marginTop:"20px" }}>{blogs[id]?.content6}</div>
      <div style={{ color: '#DCDCDC',fontSize: '30px', marginTop:"20px" }}>{blogs[id]?.title7}</div>
      <div style={{ color: '#DCDCDC',fontSize: '20px', marginTop:"20px" }}>{blogs[id]?.content7}</div>
      <div className='d-flex flex-row' style={{marginTop:"40px"}}>
        <img className="rounded-circle" style={{ height: '100px', width: '100px' }} alt="avatar1" src="/images/Harish2.JPG" />
        <div style={{ marginLeft: "25px", marginBlock:'auto', color: '#DCDCDC' }}>
          <div style={{ fontSize: '25px', color: '#DCDCDC' }}>{blogs[id]?.author}</div>
          <div style={{ fontSize: '15px', color: '#DCDCDC' }}>{blogs[id]?.designation}</div>
        </div>
      </div>
      <hr style={{height:'5px',background:'white'}}/>
    </div>
  );
};

export default BlogPage;
