import React from 'react';
import './aiModels.css'; // Import CSS file for styling
import { Link } from 'react-router-dom';

const AiModels: React.FC = () => {

  const advantages = [
    {
      image: '/images/latency.png',
      heading: 'Low Latency',
      text: 'Edge devices process data locally, reducing latency and enabling real-time applications.',
    },
    {
      image: '/images/privacy.png',
      heading: 'Privacy',
      text: 'Local data processing on edge devices enhances privacy and security by minimizing data transmission over the internet.',
    },
    {
      image: '/images/bandwidth.png',
      heading: 'Bandwidth Efficiency',
      text: 'AI models on edge devices conserve bandwidth by reducing the need for data transmission to the cloud, especially in limited or expensive network scenarios.',
    },
    {
      image: '/images/reliability.png',
      heading: 'Reliability',
      text: 'Edge devices continue to function even with intermittent or no internet connectivity, ensuring uninterrupted operation in critical scenarios.',
    },
    {
      image: '/images/scalability.png',
      heading: 'Scalability',
      text: 'Distributed computing enabled by edge devices allows for scalable deployments across diverse locations without overloading centralized servers.',
    },
    {
      image: '/images/cost-effectiveness.png',
      heading: 'Cost-effectiveness',
      text: 'Edge computing reduces the need for expensive cloud infrastructure, offering a cost-effective solution for deploying AI models.',
    },
    {
      image: '/images/offline.png',
      heading: 'Offline Capabilities',
      text: 'AI models on edge devices can operate offline, independent of internet connectivity, suitable for remote or unreliable network scenarios.',
    },
    {
      image: '/images/real-time.png',
      heading: 'Real-time Decision Making',
      text: 'Edge devices enable real-time decision-making without relying on cloud processing, crucial for applications requiring immediate action.',
    },
  ];

  
  return (
    <div className="ai-models-page">
      {/* Video Section */}
      <div className='  '>
        <video className='w-100 h-auto' muted loop autoPlay id="myVideo">
        <source src="/videos/ai_edge.mp4" type="video/mp4"></source>
        </video>
    </div>

      {/* Introduction Section */}
      <section className="introduction">
        <div className="container">
          <h1>AI Models for Edge Devices</h1>
          <p>
            AI models for edge devices bring intelligence directly to the devices, enabling real-time data processing and decision-making without relying on centralized cloud servers.
          </p>
        </div>
      </section>

      {/* How It Works Section */}
      <div className="container mt-4" style={{ backgroundColor: '#ffffff', paddingBottom:'10px' }}>
        <div className="row justify-content-center">
          <h2 style={{ color: 'black' }}>Benefits of AI Models for Edge Devices </h2>
          {advantages.map((advantage, index) => (
            <div className="col-lg-3 col-md-6 col-sm-12 mb-4" key={index}>
              <div className="card h-100" style={{ backgroundColor: '#ffffff' }}>
                <img className="card-img-top w-25 h-25" src={advantage.image} alt={`Advantage ${index + 1}`} style={{ width: '50px', height: '50px', display: 'block', margin: 'auto' }} />
                <div className="card-body">
                  <h4 className="card-title font-weight-bold" style={{ color: 'black' }}>{advantage.heading}</h4>
                  <p className="card-text" style={{ color: 'black' }}>{advantage.text}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <br></br>



      {/* How It Works Section */}
      <section className="how-it-works">
        <div className="container">
          <h2>How AI Models for Edge Devices Work</h2>
          <p>
            AI models for edge devices are trained on large datasets and then deployed directly onto edge devices. These models utilize efficient algorithms to process data locally and provide intelligent insights without relying on cloud servers.
          </p>
        </div>
      </section>

      {/* Call-to-Action Section */}
      <section className="cta">
        <div className="container">
          <h2>Explore the Potential of AI Models for Edge Devices</h2>
          <p>Contact us to discover how AI models for edge devices can revolutionize your business operations and enable innovative solutions.</p>
          <button className="btn btn-primary" style={{ background: 'linear-gradient(45deg, #007bff, #00bfff)', border: 'none', transition: 'background 0.3s' }}><Link to={`/service/contact`} className="text-white text-decoration-none">
          Contact Us
          </Link></button>
        </div>
      </section>
    </div>
  );
};

export default AiModels;

