import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Tabel from '../Tabel/Tabel';
import SmartFarming from '../SmartFarming/SmartFarming';
import SmartLogistics from '../SmartLogistics/SmartLogistics';
import Contact from '../Contact/Contact';
import TermsConditions from '../TermsConditions/TermsConditions';
import PrivacyPolicy from '../PrivacyPolicy/PrivacyPolicy';
import B100 from '../B100/B100';
import S100 from '../S100/S100';
import Blog from '../Blog/Blog';
import OurTeam from '../Team/Team';
import OurVision from '../OurVision/OurVision';
import { Careers } from "../../data";
import Agrivikas from '../Agrivikas/Agrivikas';
import DigitalTwin from '../DigitalTwins/DigitalTwins';
import AiModels from '../AiModels/aiModels';
import IndustrialIoT from '../IIot/IIot';
import AiIoTCloudServices from '../AiIotCloudServices/AiIotCloudServices';
import AdvancedAnalytics from '../AdvancedAnalytics/AdvancedAnalytics';

function Service() {
  const params = useParams();
  const [container, setContainer] = useState('');

  useEffect(() => {
    if (params.name === 'smart-farming' || params.name === 'smart-logistics') {
      setContainer('');
    } else {
      setContainer('container-fluid');
    }
  }, [params.name]);

  useEffect(() => {
    // Scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  return (
    <div className={`text-white mt-5 service ${container}`}>
      {params.name === 'careers' && <Tabel data={Careers} />}
      {params.name === 'smart-farming' && <SmartFarming />}
      {params.name === 'smart-logistics' && <SmartLogistics />}
      {params.name === 'contact' && <Contact />}
      {params.name === 'terms-conditions' && <TermsConditions />}
      {params.name === 'privacy-policy' && <PrivacyPolicy />}
      {params.name === 'our-team' && <OurTeam />}
      {params.name === 'our-vision' && <OurVision />}
      {params.name === 'B-100' && <B100 />}
      {params.name === 'Agrivikas' && <Agrivikas />}
      {params.name === 'DigitalTwin' && <DigitalTwin />}
      {params.name === 'IndustrialIoT' && <IndustrialIoT />}
      {params.name === 'AdvancedAnalytics' && <AdvancedAnalytics />}
      {params.name === 'AiIoTCloudServices' && <AiIoTCloudServices />}
      {params.name === 'AiModels' && <AiModels />}
      {params.name === 'S-100' && <S100 />}
      {params.name === 'blog' && <Blog />}
    </div>
  );
}

export default Service;
