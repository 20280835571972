import React, { useEffect } from 'react';
import CardComponent from '../Card/CardComponent';
import Recognitions from '../Recognitions/Recognitions';

import {
  carouselImages 
} from "../../data";
import Banner from '../Banner/Banner';


const Home: React.FC = () => {

  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  }, []); 

  return (
    <div>
        <Banner />
        <CardComponent {...carouselImages}/> 
        <Recognitions imageUrl="/images/startup_india.jpg" />
    </div>
  );
};

export default Home;
