import React, { useEffect, useState } from 'react';
import './S100.css';

const S100: React.FC = () => {
  const backgroundImg2 = '/images/warehouse_wallpaper.jpg';
  const [typedText, setTypedText] = useState('');
  const text = "Warehouse Automation for seamless operations";

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

    let i = 0;
    const timer = setInterval(() => {
      if (i < text.length) {
        setTypedText(prevText => prevText + text.charAt(i));
        i++;
      } else {
        clearInterval(timer);
      }
    }, 100); // Adjust typing speed here

    return () => clearInterval(timer);
  }, [text]);

  const advantages = [
    {
      image: '/images/efficiency.png',
      heading: 'Efficiency',
      text: 'Shramik-100 streamlines warehouse operations, reducing manual labor and optimizing workflows.',
    },
    {
      image: '/images/precision.png',
      heading: 'Precision',
      text: 'The robot ensures accurate picking and placing of items, minimizing errors and increasing order accuracy.',
    },
    {
      image: '/images/scale.png',
      heading: 'Scalability',
      text: 'Shramik-100 is highly scalable, allowing warehouses to easily expand their automation capabilities as needed.',
    },
    {
      image: '/images/space.png',
      heading: 'Space Optimization',
      text: 'By encouraging vertical stacking, Shramik-100 maximizes warehouse space utilization, increasing storage capacity.',
    },
    {
      image: '/images/cost.png',
      heading: 'Cost Savings',
      text: 'The automation provided by Shramik-100 reduces operational costs associated with labor, errors, and inefficiencies.',
    },
    {
      image: '/images/safety.png',
      heading: 'Safety',
      text: 'The robot enhances workplace safety by handling heavy loads and performing repetitive tasks, reducing the risk of injuries to human workers.',
    },
    {
      image: '/images/operation.png',
      heading: '24/7 Operation',
      text: 'Shramik-100 can operate continuously, enabling warehouses to fulfill orders faster and meet customer demands round the clock.',
    },
    {
      image: '/images/flexible.png',
      heading: 'Flexibility',
      text: 'The robot is adaptable to various warehouse layouts and can work in diverse environments, accommodating different warehouse configurations.',
    },
    {
      image: '/images/real_time.png',
      heading: 'Real-time Monitoring',
      text: 'Shramik-100 provides real-time data and insights into warehouse operations, enabling managers to make informed decisions and optimize processes.',
    },
    {
      image: '/images/integration.png',
      heading: 'Seamless Integration',
      text: 'The robot seamlessly integrates with existing warehouse management systems (WMS) and other automation technologies, ensuring smooth implementation and operation.',
    },
  ];

  return (
    <div className='  '>
      <div style={{ position: 'relative' }}>
        <video className='w-100 h-auto' muted loop autoPlay id="myVideo" style={{ zIndex: '1' }}>
          <source src="/videos/sr100-1.mp4" type="video/mp4"></source>
        </video>
        <div style={{ position: 'absolute', top: '10%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: '2', color: 'white', fontSize: '30px', fontWeight: 'bold', textShadow: '2px 2px 4px rgba(0,0,0,0.5)', whiteSpace: 'nowrap' }}>
          {typedText}
        </div>
      </div>

      <div style={{ paddingTop: '0px' }}>
        <div className="container-fluid" style={{ backgroundImage: `url(${backgroundImg2})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat', minHeight: '350px', marginBottom: '20px' }}>
          <div className="row justify-content-center align-items-center" style={{ minHeight: '400px' }}>
            <div className="col-md-12 text-center" style={{ backgroundColor: 'rgba(255, 255, 255, 0.5)', padding: '20px' }}>
              <h1 style={{ color: '#ed671f', textAlign: 'center' }}>Shramik-100 - A Robot dedicated for warehouses</h1>
              <h4 style={{ color: '#333333' }}>Welcome to Shramik-100, revolutionizing warehouse operations with innovation, efficiency, and sustainability. Explore the future of logistics with us!</h4>
              <p style={{ color: '#333333' }}>It's more than just a robotic system; it's an immersive journey. Dive into its functionalities and witness the mastery of precision in warehouse management.</p>
            </div>
          </div>
        </div>
      </div>

      <div className="container mt-4 mb-4">
        <div className="row justify-content-center">
          <div className="col-md-8 text-center">
            <h2 className="mb-3">Why Choose Shramik-100 for Your Warehouse?</h2>
            <p className="lead">Experience seamless automation and optimize your warehouse operations with Shramik-100.</p>
          </div>
        </div>
      </div>

      <div className="container mt-4">
        <div className="row justify-content-center">
          {advantages.map((advantage, index) => (
            <div className="col-lg-3 col-md-6 col-sm-12 mb-4" key={index}>
              <div className="card h-100" style={{ backgroundColor: '#FFA500' }}>
                <img className="card-img-top w-100 h-100" src={advantage.image} alt={`Advantage ${index + 1}`} />
                <div className="card-body">
                  <h5 className="card-title font-weight-bold" style={{ color: 'white' }}>{advantage.heading}</h5>
                  <p className="card-text" style={{ color: 'white' }}>{advantage.text}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="container mt-4 mb-4">
        <div className="row justify-content-center">
          <div className="col-md-8 text-center">
            <h2 className="mb-3">Vertical stacking for optimal space utilization</h2>
            <p className="lead">Unlock the potential of your warehouse space with strategic planning and precision execution</p>
          </div>
        </div>
      </div>

      <div className="mt-4">
        <div className="row justify-content-center">
        <div className="col-lg-4 col-md-6 col-sm-12">
        <div className="card">
          <img className="card-img-top" style={{ objectFit: 'contain', width: '100%', height: '100%' }} src="/images/sr100_2.jpg" alt="Image 1" />
        </div>
      </div>
          <div className="col-lg-4 col-md-6 col-sm-12">
            <div className="card">
              <video className='w-100 h-auto' muted loop autoPlay id="myVideo">
                <source src="/videos/sr100.mp4" type="video/mp4"></source>
              </video>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-12">
            <div className="card">
              <img className="card-img-top w-100 h-100" src="/images/sr100_3.jpg" alt="Image 3" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default S100;
