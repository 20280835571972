import { faFacebook, faTwitter, faInstagram, faYoutube, faLinkedin } from "@fortawesome/free-brands-svg-icons";

const header = {
  iconText: 'Zukunft Robots',
  menuItems: [
    {
      title: 'Agriculture Robots', link: 'agriculture-robots',
      subItems: [
        /* {
          title: 'BR-100',
          link: 'B-100'
        }*/
        {
          title: 'Agrivikas',
          link: 'Agrivikas'
        } 
      ]
    },
     {
      title: 'Warehouse Robots', link: 'logistics-robots',
      subItems: [
        {
          title: 'SR-100',
          link: 'S-100'
        }
      ]
    },
    { title: 'Smart Farming', link: 'smart-farming' },
    {
      title: 'Digital Transformation Services', link: 'digital-transformation',
      subItems: [
        {
          title: 'AI Models for Edge Devices',
          link: 'AiModels'
        },
        {
          title: 'Digital Twins',
          link: 'DigitalTwin'
        },
        {
          title: 'Industrial IOT',
          link: 'IndustrialIoT'
        },
        {
          title: 'AI & IOT Cloud Services',
          link: 'AiIoTCloudServices'
        },
        {
          title: 'Advanced Analytics for Sensor Data',
          link: 'AdvancedAnalytics'
        }
      ]
    },
    // { title: 'Smart Logistics', link: 'smart-logistics' },
    { title: 'Blog', link: 'blog' }
  ]
}

const blogs: any = [
  {
    title: "Why Smart Farming Matters More Than Ever",
    title1: "1. Cultivating Sustainably in a Changing World :",
    title2: "2. Power of Data in Farming :",
    title3: "3. From Predictive Analytics to Automated Decision-Making :",
    title4: "4. Connected Farms for a Connected World :",
    title5: "5. Meeting the Challenges of a Changing Climate :",
    title6: "6. Bridging the Agricultural Gaps :",
    title7: "Conclusion:",
    content: "Farming was a predominantly manual and labor-intensive endeavor. Today, the agricultural landscape is undergoing a radical transformation, and at the heart of this revolution is the concept of Smart Farming. In this article, we'll delve into the profound importance of Smart Farming in the modern world, exploring its impact on sustainability, efficiency, and global food security.",
    content1: "The world's population is steadily rising, and with it, the demand for food. Smart Farming champions sustainable agricultural practices that minimize waste, conserve resources, and reduce environmental impact. It optimizes resource allocation, curbing the excessive use of water, fertilizers, and pesticides while promoting soil health and biodiversity.",
    content2: "Precision agriculture is the backbone of Smart Farming, employing a wealth of data-driven technologies. Through remote sensing, GPS, and IoT devices, farmers can monitor crop conditions with unparalleled precision. This leads to optimized planting, irrigation, and pest control strategies, ultimately boosting yields and reducing production costs.",
    content3: "Artificial intelligence and machine learning algorithms process vast datasets to provide farmers with actionable insights. Smart Farming solutions can predict crop diseases, analyze weather patterns, and recommend ideal planting times. Automation of routine tasks, like weeding and harvesting, reduces labor dependency and enhances productivity.",
    content4:"The Internet of Things (IoT) has infiltrated the agricultural sector, enabling real-time monitoring of farm equipment, environmental conditions, and even livestock health. IoT devices empower farmers to make informed decisions promptly, ensuring optimal conditions for growth and livestock management.",
    content5:"Climate change poses an escalating threat to agriculture. Smart Farming equips farmers with tools to adapt to shifting weather patterns and mitigate risks. By monitoring and adjusting in real time, farmers can optimize crop yields, ensuring a stable food supply even in the face of adversity.",
    content6:"Smart Farming isn't limited to advanced economies. It has the potential to transform agriculture on a global scale, including in developing nations. Through accessible technology and knowledge sharing, Smart Farming can empower farmers worldwide, addressing food security challenges on a broader scale.",
    content7:"As we stand on the brink of a new agricultural era, Smart Farming emerges as a beacon of hope. It offers a sustainable, efficient, and technologically-driven path forward, ensuring that our planet can sustainably feed a growing population. The importance of Smart Farming has never been more evident, and its transformative power promises a future where agriculture is not just smarter but truly visionary.",
    author:"Harish Kumar",
    designation:"CTO",
    image:'/images/smart_farm_art.jpg'
  },
  {
    title: "The Robotic Revolution Redefining Farming",
    title1: "1. Automating Laborious Tasks with Precision :",
    title2: "2. Unleashing Aerial Data for Crop Management :",
    title3: "3. Power of Robot Teamwork :",
    title4: "4. Maximizing Yields and Minimizing Resources :",
    title5: "5. Orchards Managed by Robots :",
    title6: "6. Navigating the Path Forward :",
    title7: "Conclusion:",
    content: "Agriculture, the age-old practice that feeds the world, is undergoing a remarkable transformation. The integration of cutting-edge robotics is ushering in a new era of farming, one that promises unprecedented efficiency, sustainability, and productivity. In this article, we explore the groundbreaking role of robots in agriculture and the futuristic landscape they are shaping.",
    content1: "Robotic farm machinery, from autonomous tractors to precision planters, are revolutionizing the way crops are cultivated. These intelligent machines can work around the clock, tirelessly planting, tilling, and harvesting with pinpoint accuracy. By reducing human labor, they optimize resource usage and minimize waste.",
    content2: "Drones equipped with advanced sensors and cameras are taking to the skies, providing invaluable insights into crop health and field conditions. From monitoring for pests and diseases to assessing irrigation needs, these aerial robots offer real-time data for informed decision-making.",
    content3: "Swarm robotics in agriculture involves a network of small, interconnected robots that work collaboratively. These swarms can efficiently manage tasks like weeding, pollination, and even fruit picking. By working together, they complete complex operations swiftly and with high precision.",
    content4:"Robots equipped with sensors and AI algorithms gather data on soil composition, moisture levels, and plant health. This data is then used to make precise decisions about planting, fertilization, and irrigation, maximizing crop yields while minimizing resource usage.",
    content5:"In orchards, robots can handle tasks like pruning, thinning, and harvesting fruit. These specialized machines ensure that each tree receives the care it needs, leading to healthier orchards and higher-quality produce.",
    content6:"While agricultural robotics promise immense benefits, challenges remain. Balancing technological advancements with environmental sustainability and addressing concerns about job displacement are crucial aspects of this journey into the future.",
    content7:"The integration of robots into agriculture represents a seismic shift in the way we produce food. By harnessing technology and automation, we can create more sustainable, efficient, and resilient farms. The agricultural robots of today are shaping the farms of tomorrow, and their impact on the world's food production is nothing short of revolutionary.",
    author:"Harish Kumar",
    designation:"CTO",
    image:'/images/smart_farm_art2.gif'
  },
  {
    title: "Leveraging Digital Twins for Robotics",
    title1: "1. Enhanced Predictive Maintenance :",
    title2: "2. Real-time Monitoring and Control :",
    title3: "3. Simulation and Training :",
    title4: "4. Optimized Operations :",
    title5: "5. Applications in Various Industries :",
    title6: "Conclusion :",
    content: "Digital twins have emerged as a transformative technology in robotics, offering virtual replicas of physical robotic systems. In this article, we delve into the integration of digital twins in robotics and their profound impact on automation across industries.",
    content1: "Digital twins enable predictive maintenance by continuously monitoring the performance of robotic systems. By analyzing data in real-time, operators can identify potential issues and schedule maintenance tasks proactively, minimizing downtime and optimizing asset performance.",
    content2: "Real-time monitoring and control capabilities provided by digital twins empower operators to oversee robotic operations remotely. This enables proactive decision-making, troubleshooting, and optimization of processes, enhancing operational efficiency and responsiveness.",
    content3: "Digital twins serve as virtual environments for simulating and training robotic systems. Operators can test different scenarios, validate control algorithms, and train operators in a risk-free simulated environment, accelerating the development and deployment of robotic applications.",
    content4: "Optimized operations are achieved through insights provided by digital twins. By analyzing data from digital twins, operators can identify optimization opportunities, streamline workflows, and enhance resource utilization, leading to improved operational efficiency and productivity.",
    content5: "Digital twins find applications across various industries, including manufacturing, healthcare, logistics, and agriculture. In manufacturing, digital twins optimize production processes and improve product quality. In healthcare, they support surgical robotics and remote patient monitoring. In logistics, they enhance efficiency and accuracy in warehouse operations. In agriculture, they revolutionize precision farming and crop management.",
    content6: "In conclusion, the integration of digital twins in robotics represents a significant advancement in automation technology. By providing real-time insights, predictive capabilities, and simulation-based tools, digital twins empower operators to optimize robotic operations, drive innovation, and shape the future of automation across industries.",
    author:"Harish Kumar",
    designation:"CTO",
    image:'/images/digitaltwin.png'
  }
  
]

const carouselImages = {
  carouselImages: [
    {
      image: {
        src: '/images/AI.jpg'
      },
      title: "Artificial Intelligence",
      descripton: "AI has emerged as a transformative force across various industries, including agriculture and logistics. By leveraging AI technologies, businesses can overcome numerous challenges and achieve higher efficiency, productivity, and sustainability. In this context, Zukunft Robots Private Limited has developed innovative solutions to bridge the gaps in these sectors, offering promising advancements and benefits.",
      link: {
        text: '',
        href: 'artificial-intellignece'
      }
    },
    {
      image: {
        src: '/images/iot.jpg'
      },
      title: "Internet of Things",
      descripton: "The Internet of Things (IoT) is offering new opportunities for connectivity, data collection, and automation. IoT solutions have the potential to address challenges and drive innovations in sectors such as agriculture, logistics, and beyond. It focuses on how IoT can revolutionize industries and highlights the efforts of innovative companies, such as Zukunft Robots Pvt. Ltd., in filling the gaps with their IoT-based solutions.",
      link: {
        text: '',
        href: 'iot'
      }
    },
    {
      image: {
        src: '/images/cloud_computing.jpg'
      },
      title: "Cloud Computing",
      descripton: "Cloud computing revolutionizing businesses standards by providing on-demand access to computing resources, storage, and services. This technology drives innovation and solutions have proven to be instrumental in sectors such as agriculture, logistics, and beyond. These ways, cloud computing is shaping industries and Zukunft Robots Pvt. Ltd trying to in bridge gaps with their cloud-based innovations.",
      link: {
        text: '',
        href: 'cloud-computing'
      }
    },
  ]
}

const Footer = {
  links: [
    {
      title: "Terms&Conditions",
      href: "terms-conditions"
    },
    {
      title: "Privacy policy",
      href: "privacy-policy"
    },
    {
      title: "Contact",
      href: "contact"
    },
    {
      title: "Careers",
      href: "careers",
    }
  ],
  objectives: [
    {
      title: 'Our Vision',
      href: 'our-vision'
    },
    /* {
      title:'Our Products',
      href:'our-products'
    },  
    {
      title:'Our Services',
      href:'our-services'
    },  */
    {
      title: 'Our Team',
      href: 'our-team'
    },
  ],
  socialMedia: [
    {
      link: 'https://www.facebook.com/profile.php?id=100091518795433',
      icon: faFacebook,
      title: "Facebook"
    },
    {
      link: 'https://www.instagram.com/',
      icon: faInstagram,
      title: "Instagram"

    },
    {
      link: 'https://twitter.com/',
      icon: faTwitter,
      title: "Twitter"
    },
    {
      link: 'https://www.youtube.com/channel/UCysRQoi3w6k2THMBbsd3j6A',
      icon: faYoutube,
      title: "Youtube"
    },
    {
      link: 'https://www.linkedin.com/company/zukunft-robots-private-limited/?viewAsMember=true',
      icon: faLinkedin,
      title: "LinkedIn"
    }
  ]

}

const Careers = [
  /* {
    Date: '15-06-2023',
    Role: 'Research and Development Intern',
    Specialization: 'Software'
  },
  {
    Date: '10-07-2023',
    Role: 'Robotic Operating System Engineer(ROS)',
    Specialization: 'Software'
  }, */
  {
    Date: '24-05-2024',
    Role: 'Senior Robotics Engineer',
    Specialization: 'Software & Robotics Systems'
  },
  {
    Date: '24-05-2024',
    Role: 'Senior Embedded Engineer',
    Specialization: 'Software & Embedded Systems'
  }
]
const roleNres = [
  /* {
    id: 0,
    title:'Internship in Embedded Systems',
    descriptions: [
      {
        title:'Your Responsibilty',
        responsibilties:[
          '1:Collaborating with the team to develop and enhance embedded systems software, firmware, and hardware components.',
          '2:Conducting testing and debugging activities to identify and resolve issues in embedded systems, ensuring their proper functionality.',
          '3:Documenting software and hardware designs, test procedures, and project progress reports to maintain clear records and facilitate effective communication.',
          '4:Assisting in the integration of embedded systems with other hardware or software components, ensuring seamless operation and compatibility.',
          '5.Conducting research on emerging technologies, industry trends, and best practices in embedded systems to contribute innovative ideas and solutions to ongoing projects.',
        ]
      },
      {
        title:'Your Profile',
        responsibilties:[
          '1:Proficient in programming languages such as C/C++ and familiarity with embedded programming techniques, real-time operating systems (RTOS), and microcontrollers/microprocessors.',
          '2:Understanding of digital and analog electronics, circuit design, and experience with hardware development tools such as schematic capture and PCB layout software.',
          '3:Strong analytical and problem-solving skills to troubleshoot and debug embedded systems issues and propose effective solutions.',
          '4:Good interpersonal skills to collaborate with team members, effectively communicate ideas, and contribute to group projects in a collaborative environment.',
        ]
      }
    ]
  },
  {
    id: 1,
    title:'Software Development Engineer',
    descriptions: [
      {
        title:'Your Responsibilty',
        responsibilties:[
          '1:Collaborating with the team to develop and enhance embedded systems software, firmware, and hardware components.',
          '2:Conducting testing and debugging activities to identify and resolve issues in embedded systems, ensuring their proper functionality.',
          '3:Documenting software and hardware designs, test procedures, and project progress reports to maintain clear records and facilitate effective communication.',
          '4:Assisting in the integration of embedded systems with other hardware or software components, ensuring seamless operation and compatibility.',
          '5.Conducting research on emerging technologies, industry trends, and best practices in embedded systems to contribute innovative ideas and solutions to ongoing projects.',
        ]
      },
      {
        title:'Your Profile',
        responsibilties:[
          '1:Strong programming skills in languages such as Java, C++, or Python, with a solid understanding of object-oriented programming principles.',
          '2:Proficiency in software development methodologies and tools, including version control systems, issue tracking, and agile development practices.',
          '3:Experience with software architecture and design patterns, as well as knowledge of database systems and SQL.',
          '4:Familiarity with web development frameworks, APIs, and cloud technologies.',
          '5:Strong problem-solving and analytical skills, with the ability to analyze complex problems, propose innovative solutions, and make data-driven decisions.',
        ]
      }
    ]
  },
  {
    id: 2,
    title:'Design Engineer',
    descriptions: [
      {
        title:'Your Responsibilty',
        responsibilties:[
          '1:Collaborating with cross-functional teams to understand project requirements, specifications, and constraints for mechanical designs.',
          '2:Creating detailed 3D models, assemblies, and technical drawings using computer-aided design (CAD) software.',
          '3:Conducting feasibility studies, analyzing design options, and proposing innovative solutions to meet performance, cost, and manufacturing requirements.',
          '4:Performing engineering calculations, simulations, and testing to validate and optimize mechanical designs.',
          '5:Generating comprehensive documentation, including design specifications, reports, and technical drawings, to ensure accurate communication and facilitate the manufacturing process.'
        ]
      },
      {
        title:'Your Profile',
        responsibilties:[
          '1:Bachelors degree in Mechanical Engineering or a related field.',
          '2:Proficiency in computer-aided design (CAD) software, such as SolidWorks, AutoCAD, or CATIA.',
          '3:Strong knowledge of mechanical engineering principles, including materials, mechanics, and thermodynamics.',
          '4:Experience in designing mechanical components and systems, such as machinery, equipment, or product enclosures.',
          '5:Familiarity with industry standards, regulations, and best practices for mechanical design and manufacturing processes.',
        ]
      }
    ]
  },
  {
    id: 3,
    title:'Internship in Design Engineer',
    descriptions: [
      {
        title:'Your Responsibilty',
        responsibilties:[
          'Assisting in the development and creation of product design concepts and prototypes.',
          'Collaborating with the design team to generate 2D and 3D models using computer-aided design (CAD) software.',
          'Conducting research and analysis to support design decisions, including materials selection and manufacturing processes.',
          'Participating in design reviews and providing input on design improvements and modifications.',
          'Assisting in the preparation of design documentation, including technical drawings, specifications, and reports.',
        ]
      },
      {
        title:'Your Profile',
        responsibilties:[
          'Currently pursuing a degree in Engineering, Industrial Design, or a related field.',
          'Familiarity with CAD software, such as SolidWorks, AutoCAD, or Fusion 360.',
          'Basic understanding of design principles, including aesthetics, functionality, and manufacturability.',
          'Strong problem-solving skills and attention to detail.',
          'Effective communication and teamwork abilities.',
        ]
      }
    ]
  },
  {
    id: 4,
    title:'Internship in Computer Vision',
    descriptions: [
      {
        title:'Your Responsibilty',
        responsibilties:[
          '1:Assisting in the development and implementation of computer vision algorithms and models.',
          '2:Collaborating with the research team to collect and label training data for computer vision tasks.',
          '3:Conducting experiments and evaluations to analyze the performance of computer vision algorithms.',
          '4:Assisting in the optimization and fine-tuning of computer vision models for specific applications.',
          '5:Participating in team meetings and discussions to contribute ideas and insights for improving computer vision systems.'
        ]
      },
      {
        title:'Your Profile',
        responsibilties:[
          '1:currently pursuing a degree in Computer Science, Electrical Engineering, or a related field.',
          '2:Familiarity with programming languages such as Python, C++, or MATLAB.',
          '3:Knowledge of computer vision concepts and algorithms, including image processing, object detection, and tracking.',
          '4:Experience with deep learning frameworks such as TensorFlow or PyTorch.',
          '5:Strong problem-solving skills and ability to analyze and interpret experimental results.',
        ]
      }
    ]
  },
  {
    id: 5,
    title:'Data Engineer',
    descriptions: [
      {
        title:'Your Responsibilty',
        responsibilties:[
          '1:Designing, building, and maintaining scalable and efficient data pipelines and ETL (Extract, Transform, Load) processes.',
          '2:Developing and implementing data integration and ingestion strategies to collect and store large volumes of data from various sources.',
          '3:Building and optimizing data storage and retrieval systems, including databases, data warehouses, and data lakes.',
          '4:Collaborating with cross-functional teams to understand data requirements and design appropriate data models and schemas.',
          '5:Implementing data quality and data governance processes to ensure the accuracy, consistency, and integrity of the data.',
        ]
      },
      {
        title:'Your Profile',
        responsibilties:[
          '1:Bachelors or Masters degree in Computer Science, Data Engineering, or a related field.',
          '2:Proficiency in programming languages such as Python, Java, or Scala.',
          '3:Experience with distributed computing frameworks like Apache Hadoop, Apache Spark, or Apache Flink.',
          '4:Strong knowledge of SQL and experience working with relational databases and query optimization.',
          '5:Familiarity with data modeling and database design principles.',
          '6:Experience with data integration and ETL tools such as Apache Kafka, Apache Nifi, or Talend.',
          '7:Understanding of cloud platforms and technologies, such as AWS, GCP, or Azure.',
          '8:Knowledge of big data processing technologies like Apache Hive or Apache Pig.',
          '9:Familiarity with data visualization tools like Tableau or Power BI.',
          '10.Strong problem-solving skills and attention to detail.',
        ]
      }
    ]
  },
  {
    id: 7,
    title:'Cloud Engineer',
    descriptions: [
      {
        title:'Your Responsibilty',
        responsibilties:[
          '1:Designing and implementing scalable and secure cloud infrastructure solutions, leveraging platforms such as AWS, Azure, or Google Cloud.',
          '2:Deploying and managing cloud services, including virtual machines, containers, serverless functions, and databases.',
          '3:Monitoring and optimizing cloud infrastructure performance, availability, and cost efficiency.',
          '4:Implementing security measures and best practices to protect cloud resources and data.',
          '5:Collaborating with cross-functional teams to develop and deploy cloud-based applications and services.',
        ]
      },
      {
        title:'Your Profile',
        responsibilties:[
          '1:Bachelors degree in Computer Science, Information Technology, or a related field.',
          '2:Strong knowledge of cloud computing concepts and technologies, with expertise in one or more cloud platforms (e.g., AWS, Azure, Google Cloud).',
          '3:Experience with infrastructure-as-code tools (e.g., Terraform, CloudFormation) for provisioning and managing cloud resources.',
          '4:Proficiency in scripting and automation using languages like Python, Bash, or PowerShell.',
          '5:Familiarity with containerization technologies (e.g., Docker, Kubernetes) and orchestration tools.',
          '6:Understanding of networking concepts, including virtual private clouds (VPCs), subnets, and security groups.',
          '7:Knowledge of cloud storage and database services (e.g., S3, RDS, DynamoDB) and their configuration and optimization.',
          '8:Experience with monitoring and logging tools (e.g., CloudWatch, Azure Monitor) for tracking cloud infrastructure performance.',
          '9:Understanding of cloud security best practices, including identity and access management (IAM), encryption, and network security.',
          '10:Strong problem-solving and troubleshooting skills, with the ability to diagnose and resolve cloud infrastructure issues.',
        ]
      }
    ]
  }, */
  {
    id: 0,
    title: 'Senior Robotics Engineer',
    descriptions: [
      {
        title: 'Your Responsibilty',
        responsibilties: [
          "Lead the design, development, and implementation of robotic systems for warehouse automation",
          "Collaborate with cross-functional teams to integrate robotic systems into warehouse environments",
          "Develop algorithms for navigation, localization, and path planning",
          "Design and optimize control systems for precise positioning and manipulation of shelves and goods",
          "Conduct testing and validation of robotic systems in simulated and real-world environments",
          "Stay current with advancements in robotics technology",
          "Mentor junior engineers"
      ]
      },
      {
        title: 'Your Profile',
        responsibilties: [
          "Bachelor's degree or higher in Robotics, Electrical Engineering, or a related field",
          "3+ years of experience in robotics system design and development",
          "Proficiency in robotics software frameworks such as ROS",
          "Strong programming skills in C++, Python",
          "Experience with sensors and perception systems",
          "Knowledge of control theory, motion planning algorithms, and localization techniques",
          "Excellent problem-solving skills"
      ]
      }
    ]
  },
  {
    id: 1,
    title: 'Senior Embedded Engineer ',
    descriptions: [
      {
        title: 'Your Responsibilty',
        responsibilties: [
          "Lead the design and development of embedded systems for robotic platforms",
          "Architect and implement firmware for embedded microcontrollers",
          "Collaborate with hardware engineers to define system architecture",
          "Develop device drivers and low-level software",
          "Conduct testing and debugging of embedded systems",
          "Work closely with software engineers to integrate embedded systems",
          "Provide technical guidance and mentorship"
      ]
      },
      {
        title: 'Your Profile',
        responsibilties: [
          "Bachelor's degree or higher in Electrical Engineering, Computer Engineering, or a related field",
          "3+ years of experience in designing and implementing embedded systems",
          "Proficiency in embedded C/C++ programming",
          "Strong understanding of microcontroller architectures and communication protocols",
          "Experience with version control systems and debugging tools",
          "Familiarity with FPGA development and DSP is a plus",
          "Excellent communication skills"
      ]
      }
    ]
  },
  /* {
    id: 2,
    title: 'Bachelor/Master Thesis on IoT-Enabled Environmental Monitoring in Smart Warehouse',
    descriptions: [
      {
        title: 'Your Responsibilty',
        responsibilties: [
          'Conduct an in-depth literature review to understand the current state-of-the-art in smart warehousing, IoT, and robotics applications.',
          'Collaborate with our experienced team to design and develop an innovative smart warehouse prototype, incorporating IoT sensors, robotics, and automation technologies.',
          'Implement and deploy IoT devices, sensors, and robotic components within the warehouse environment.',
          'Analyze and interpret data collected from the smart warehouse prototype, extracting meaningful insights to enhance logistics processes.',
          'Investigate the impact of IoT-enabled solutions on inventory management, order fulfillment, and overall warehouse efficiency.',
          'Optimize the smart warehouse system through iterative testing and improvement cycles.',
          'Document your findings, methodologies, and conclusions in a comprehensive thesis report.',
          'Present your research and results to the Zukunft Robots team, showcasing your contributions and recommendations.'
        ]
      },
      {
        title: 'Your Profile',
        responsibilties: [
          'Bachelor or Master student pursuing a degree in Engineering, Computer Science, Robotics, or a related field.',
          'Strong interest in robotics, automation, IoT, and their applications in warehouse and logistics domains.',
          'Basic knowledge of programming languages such as Python, C++, or Java.',
          'Curiosity, creativity, and a problem-solving mindset to address real-world challenges.',
          'Excellent communication skills, both written and verbal.',
          'Ability to work independently and as part of a collaborative team.'
        ]
      }
    ]
  } */
]

const aboutData = {
  title: "About Zukunft Robots",
  paragraphs: [
    "Zukunft Robots is a forward-thinking startup focused on pushing the boundaries of automations robotics technology. With our team of passionate engineers and developers, we're committed to providing digital transformation services that streamline processes, boost productivity, and drive efficiency for businesses worldwide.",

    "We envision a future where industries thrive with the help of robotics and automations. By embracing innovation, businesses can unlock new opportunities, drive growth, and create a more sustainable future.",

    "Find more at :"
  ],
  website: "zukunftrobots.com"
};

const emailjsIds = {
  EMAILJS_SERVICE_ID: 'service_6wf6bfa',
  EMAILJS_TEMPLATE_ID: 'template_59uqq86',
  EMAILJS_PUBLIC_KEY: '9bOtyDsXuyDsKiX9W'
};

const termsConditions = {
  title: "Terms and Conditions",
  sections: [
    {
      title: "1. Compliance and Acceptance",
      paragraphs: [
        "By accessing and using this website, you agree to comply with and be bound by the following terms and conditions.",
      ],
    },
    {
      title: "2. General Information",
      paragraphs: [
        "The content of this website is for general information purposes only and is subject to change without notice.",
      ],
    },
    {
      title: "3. Disclaimer of Warranty",
      paragraphs: [
        "We do not provide any warranty or guarantee as to the accuracy, completeness, or suitability of the information and materials found on this website.",
        "Your use of any information or materials on this website is entirely at your own risk, and we shall not be liable for any damages arising from such use.",
      ],
    },
    {
      title: "4. External Links",
      paragraphs: [
        "This website may contain links to other websites. These links are provided for your convenience to provide further information. We do not endorse the linked websites and have no responsibility for the content or accuracy of the linked websites.",
      ],
    },
    {
      title: "5. Unauthorized Use",
      paragraphs: [
        "Unauthorized use of this website may give rise to a claim for damages and/or be a criminal offense.",
      ],
    },
    {
      title: "6. Governing Law",
      paragraphs: [
        "Your use of this website and any dispute arising out of such use is subject to the laws of India.",
      ],
    },
    {
      title: "7. Modification and Discontinuation",
      paragraphs: [
        "We reserve the right to modify or discontinue, temporarily or permanently, the website or any part of it without notice.",
      ],
    },
    {
      title: "8. Changes to Terms and Conditions",
      paragraphs: [
        "These terms and conditions are subject to change at any time, and it is your responsibility to check for any updates or changes.",
      ],
    },
  ],
};


const privacyPolicy = {
  title: "Privacy Policy",
  sections: [
    {
      title: "Information We Collect",
      paragraphs: [
        "We may collect personal information, such as your name, email address, and phone number, when you interact with our website or services.",
        "We also collect non-personal information, such as your IP address, browser type, and operating system, to analyze trends, administer the site, and gather demographic information.",
      ],
    },
    {
      title: "How We Use Your Information",
      paragraphs: [
        "We use your personal information to provide and improve our services, communicate with you, and personalize your experience.",
        "We may also use your information to send you promotional emails or newsletters, but you can opt out of receiving those communications at any time.",
      ],
    },
    {
      title: "Information Sharing and Disclosure",
      paragraphs: [
        "We do not sell, trade, or otherwise transfer your personal information to outside parties without your consent.",
        "However, we may share your information with trusted third-party service providers who assist us in operating our website and providing our services.",
      ],
    },
    {
      title: "Data Security",
      paragraphs: [
        "We take reasonable measures to protect the security of your personal information and prevent unauthorized access or disclosure.",
        "However, no method of transmission over the internet or electronic storage is 100% secure, so we cannot guarantee absolute security.",
      ],
    },
    {
      title: "Changes to This Privacy Policy",
      paragraphs: [
        "We may update this privacy policy from time to time. Any changes will be posted on this page, and the revised policy will be effective immediately upon posting.",
        "It is your responsibility to review this policy periodically to stay informed about any updates or modifications.",
      ],
    },
  ],
};

const smartFarm = {
  title: "Real Time Weed Detection",
  description: [
    "Our advanced robotic systems revolutionize weed management. Efficiently identifying and targeting weeds, they minimize competition, enhance crop yields, and reduce herbicide dependency. Optimizing resources, minimizing labor, and ensuring healthier crops, we contribute to a sustainable and profitable agricultural sector."
  ],
  title1: "Real Time Crop Disease Detection",
  description1: [
    "Explore the cutting-edge world of disease detection powered by AI on our website. Our advanced technology harnesses the power of artificial intelligence to swiftly identify and manage plant diseases, ensuring robust crop health and sustainability in agriculture. Witness the future of precision farming through our AI Model"
  ]
};

const smartLogistics = {
  title: "Real Time Label Defect Detection",
  description: [
    " Our defect detection solutions are transforming quality assurance in various industries. By swiftly identifying and pinpointing defects on labels and packaging, our technology minimizes errors in production, elevates product quality, and reduces material waste. We optimize resource utilization, reduce the need for manual inspection, and ensure consistent product excellence, ultimately contributing to more efficient and cost-effective manufacturing processes."
  ]
};

const portfolio = [
  {
    name: "Swetha Baddam",
    role: "Co-Founder & Chief Executive Officer",
    picture: "/images/swetha.jpeg",
  },
  /* {
    name: "Krishna Goud Nimmala",
    role: "Chief Legal Officer",
    picture: "/images/krishna.jpeg",
  }, */
  {
    name: "Harish Kumar N",
    role: "Co-Founder & Chief Technology Officer",
    picture: "/images/Harish2.JPG",
  },
  {
    name: "Francis B",
    role: "Chief Marketing Officer",
    picture: "/images/francis.jpeg",
  },
   {
    name: "Shiva Naga",
    role: "Chief Financial Officer",
    picture: "/images/shiva_naga.jpeg",
  },
  {
    name: "Akash Reddy K",
    role: "Senior Embedded & IOT Engineer",
    picture: "/images/akash.jpg",
  },
  {
    name: "Sai Teja K",
    role: "Senior Fullstack Consultant",
    picture: "/images/teja.jpg",
  },
  {
    name: "Shivudu Dhandu",
    role: "Development Engineer",
    picture: "/images/shivudu.jpeg",
  },
  {
    name: "Keerthi Varmaa R",
    role: "Robotics Engineer",
    picture: "/images/keerthi_varma.jpg",
  },
  {
    name: "Sudheer Reddy A",
    role: "Senior AI Consultant",
    picture: "/images/sudheer.jpeg",
  },
  {
    name: "Somesh Basani A",
    role: "Robotics Trainee",
    picture: "/images/somesh.jpg",
  }
];

const visionContent = [
  {
    title: "Our Vision",
    content: [
      {
        heading: "Revolutionizing Agriculture, Manufacturing, and Logistics",
        text: "At Zukunft Robots, our vision is to revolutionize the fields of agriculture, manufacturing, and logistics through the development of futuristic robots and digital transformation services. We aim to create cutting-edge products and services that leverage the latest technologies to transform these industries."
      },
      {
        heading: "Futuristic Robots and Digital Services for Agriculture",
        text: "Our primary focus is on developing robots and digital services tailored for the agriculture sector. From autonomous farming robots to precision agriculture solutions, we offer innovative tools to optimize crop management, increase yields, and reduce labor costs."
      },
      {
        heading: "Robotic Solutions for Manufacturing Excellence",
        text: "In the manufacturing sector, we provide a range of robotic solutions designed to enhance operational efficiency and productivity. From collaborative robots for assembly tasks to automated guided vehicles for material handling, our robots streamline production processes and improve throughput."
      },
      {
        heading: "Digital Transformation Services for Efficient Warehousing",
        text: "For warehouses and logistics operations, we offer digital transformation services aimed at optimizing warehouse management and logistics operations. Our solutions include AI-powered inventory management systems, autonomous warehouse robots, and predictive analytics for supply chain optimization."
      },
      {
        heading: "Innovation and Sustainability",
        text: "With a strong commitment to innovation and sustainability, we are dedicated to shaping the future of agriculture, manufacturing, and logistics. Our goal is to empower businesses and individuals with the tools they need to thrive in the rapidly evolving landscape of these industries."
      },
      {
        heading: "Collaborative Approach",
        text: "We believe in a collaborative approach, working closely with our clients and partners in agriculture, manufacturing, and logistics to understand their unique needs and challenges. By forging strong relationships, we can co-create solutions that address industry-specific requirements and drive meaningful advancements."
      },
      {
        heading: "Constant Innovation",
        text: "Innovation is at the heart of our company. We continuously invest in research and development to stay at the forefront of technological advancements. By embracing emerging technologies and exploring new possibilities, we push the boundaries of what is possible in the realm of robotics and automation."
      },
      {
        heading: "Empowering the Future",
        text: "Our ultimate aim is to empower the future of agriculture, manufacturing, and logistics. Through our advanced robots, digital services, and transformative technologies, we aspire to create sustainable and efficient ecosystems that benefit businesses, communities, and the global economy."
      },
    ],
  },
];


export default visionContent;

export {
  header,
  carouselImages,
  Footer,
  Careers,
  roleNres,
  aboutData,
  emailjsIds,
  termsConditions,
  privacyPolicy,
  smartFarm,
  smartLogistics,
  portfolio,
  blogs
};

