import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import './Tabel.css';
import { Link } from 'react-router-dom';


interface TabelProps {
  data: any[] | undefined;
}

const Tabel: React.FC<TabelProps> = ({ data }) => {
  const [headings, setHeadings] = useState<string[]>([]);
  const navigate = useNavigate();
  const backgroundImg = '/images/careers_wallpaper.jpg'; 
  const backgroundImg1 = '/images/careers_wallpaper2.jpg'; 
  const tableRef = useRef<HTMLTableElement>(null); // Ref for the table

  const handleClick = (index: number) => {
    navigate(`/careers/${index}`);
  };

  useEffect(() => {
    if (data) {
      setHeadings(Object.keys(data[0]));
    }
  }, [data]);

  return (
    <div className="careers">
      <div className="container-fluid" style={{ backgroundImage: `url(${backgroundImg})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat', minHeight: '420px' }}>
        <div className="row justify-content-center align-items-center" style={{ minHeight: '420px' }}>
          <div className="col-md-12 text-center">
            <h2 style={{ color: '#FFFFFF' }}>Transform your passion for technology into a rewarding profession </h2>
            <p style={{ color: '#FFFFFF' }}>become part of a collaborative community where individuals like you unite to achieve extraordinary feats!</p>
            <button className="btn btn-primary" style={{ background: 'linear-gradient(45deg, #FFA500, #FF4500)', border: 'none', transition: 'background 0.3s' }} onClick={() => tableRef.current?.scrollIntoView({ behavior: 'smooth' })}>
              Open Positions
            </button>
          </div>
        </div>
      </div>
      <div className="container-fluid" style={{ backgroundImage: `url(${backgroundImg1})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat', minHeight: '400px' }}>
        <div className="row justify-content-center align-items-center" style={{ minHeight: '450px' }}>
          <div className="col-md-12 text-center">
            <h2 style={{ color: '#FFFFFF' }}>Discover your true identity at Zukunft Robots</h2>
            <p style={{ color: '#FFFFFF' }}>We prioritize building enduring connections, not only with clients but also with our valued team members.</p>
          </div>
        </div>
      </div>

        <div className="container-fluid mt-5">
        <div className="bg-white p-4">
          <h2 className="text-center mb-4" style={{ color: '#000000' }}>Our values sculpt our culture</h2>
          <div className="row">
            <div className="col-md-4 mb-4">
              <div className="card h-100" style={{ background: '#6CA6CD' }}>
                <div className="card-body">
                  <h5 className="card-title text-center" style={{ color: '#FFFFFF' }}>
                    <i className="icon-innovation" style={{ color: '#FFFFFF' }}></i> Innovation
                  </h5>
                  <p className="card-text" style={{ color: '#FFFFFF' }}>
                    Embracing innovation, we push the boundaries of technology, transforming ideas into groundbreaking solutions and redefining what's possible.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4 mb-4">
              <div className="card h-100" style={{ background: '#6CA6CD' }}>
                <div className="card-body">
                  <h5 className="card-title text-center" style={{ color: '#FFFFFF' }}>
                    <i className="icon-collaboration" style={{ color: '#FFFFFF' }}></i> Collaboration
                  </h5>
                  <p className="card-text" style={{ color: '#FFFFFF' }}>
                    United in purpose, we foster the power of teamwork, coming together to achieve collective success, inspiring each other to new heights.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4 mb-4">
              <div className="card h-100" style={{ background: '#6CA6CD' }}>
                <div className="card-body">
                  <h5 className="card-title text-center" style={{ color: '#FFFFFF' }}>
                    <i className="icon-excellence" style={{ color: '#FFFFFF' }}></i> Excellence
                  </h5>
                  <p className="card-text" style={{ color: '#FFFFFF' }}>
                    Driven by a commitment to excellence, we relentlessly pursue perfection in all our endeavors, delivering top-notch quality in every product and service we offer.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <button className="btn btn-primary"style={{background: 'linear-gradient(45deg, #007BFF, #00BFFF)',border: 'none',transition: 'background 0.3s',}}><Link to={`/service/our-team`} className="text-white text-decoration-none">Join our Team</Link></button>
        </div>
        </div>
      </div>

      {/*<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
      <div>
      <center><h1>Job Openings</h1></center>
      <br></br>
      <p>We currently do not have any job openings. Please check back later for updates.</p>
    </div>
    </div>*/}

       {/* This code is for career table. We can uncomment this when we have any recruitments */}
       <div className="container-fluid table-responsive"> 
        <table ref={tableRef} className="table" style={{ marginTop: '20px' }}>
          <thead className="table-dark">
            <tr>
              <th scope="col" className="text-white text-center">
                Sno.
              </th>
              {headings?.map((title, index) => (
                <th key={index} scope="col" className="text-white text-center">
                  {title}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data?.map((item, index) => (
              <tr key={index} onClick={() => handleClick(index)}>
                <th
                  scope="row"
                  className="text-black text-center"
                  style={{ background: 'linear-gradient(to right, #FFFFFF, #FFFFFF) ' }}
                >
                  {index + 1}
                </th>
                {headings?.map((title, headIndex) => (
                  <td
                    key={headIndex}
                    className="text-black text-center"
                    style={{ background: 'linear-gradient(to right, #FFFFFF, #FFFFFF)' }}
                  >
                    <a>{item[title]}</a>
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div> 

    </div>
  );
};

export default Tabel;
