import React from 'react';
import { Link } from 'react-router-dom';
import "@popperjs/core";
import "./Header.css";

const Header: React.FC<any> = ({ iconText, menuItems }: any) => {

  function closeNavbar(e:any) {
    console.log(e)
    $('.navbar-toggler').click(); // Trigger the toggle button click event
  }

  return (
    <div className='header'>
      <nav className=" main py-1 fixed-top navbar navbar-expand-lg  border-bottom border-white">
        <div className="container-fluid">
          <Link to="/" className="navbar-brand rounded ">
            <img src="/images/logo.png" alt="Logo" className="logo" width="200" height="80" />
          </Link>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon ">
              <span className='line'></span>
              <span className='line'></span>
              <span className='line'></span>
            </span>
          </button>
          <div className="collapse navbar-collapse " id="navbarNavDropdown">
            <ul className="navbar-nav m-auto">
              {
                menuItems.map((item: any, index: number) => {
                  if (!item.subItems) {
                    return (
                      <li className="nav-item ms-2" key={index} onClick={(e)=>closeNavbar(e)}>
                        <Link to={`service/${item.link}`} className="nav-link active text-white heading" aria-current="page">{item.title}</Link>
                      </li>
                    );
                  } else {
                    return (
                      <li className="nav-item dropdown ms-2" key={index} onClick={(e)=>closeNavbar(e)}>
                      {/* <li className="nav-item dropdown ms-2" key={index}> */}
                        <a className="text-white nav-link dropdown-toggle" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true">
                          {item.title}
                        </a>
                        <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                          {
                            item.subItems.map((subItem: any, subIndex: number) =>
                              <Link to={`service/${subItem.link}`} className="dropdown-item nav-link" aria-current="page">{subItem.title}</Link>
                            )
                          }
                        </div>
                      </li>
                    );
                  }
                })
              }
            </ul>
          </div>
          <button
          type="button"
          className="button py-2 px-4 rounded-pill d-none d-lg-block"
          style={{
            background: "linear-gradient(45deg, #006400, #003300)",
            border: "none",
            color: "white",
            transition: "background-color 0.3s ease",
          }}
        >
          <Link to={`service/careers`} className="text-white text-decoration-none">
            Careers
          </Link>
        </button>
        </div>
      </nav>
    </div>
  );
};

export default Header;
